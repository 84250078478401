<div class="modal-content">
  <form *ngIf="popupForm" [formGroup]="popupForm" >
    <div class="modal-header">
      <h5 class="modal-title">{{showComponentName}} Field</h5>
      <button class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closePopup()"></button>
    </div>
    <div class="modal-body">
      <!-- Label -->
      <div class="form-floating  mb-3" *ngIf="componentsElement.label && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type="text" class="form-control" formControlName="label" id="label" name="label"
          placeholder="Enter Label" max="500" maxlength="500" required autocomplete="off">
        <label for="label"><b>Label</b></label>
        <i><b>Note:</b> Maximium character allowed 500</i>
      </div>
      <!-- Annotated Name -->
      <div class="form-floating mb-3 " *ngIf="componentsElement.annotatedName && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type="text" class="form-control" id="annotatedName" formControlName="annotatedName" name="annotatedName"
          placeholder="Enter Annotated Name" maxlength="500" max="500" autocomplete="off">
        <label for="annotatedName"><b>Annotated Name</b></label>
        <i><b>Note:</b> Maximium character allowed 500</i>
      </div>
      <!-- Tag Name -->
      <div class="form-floating mb-3 " *ngIf="componentsElement.tag && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type="text" class="form-control" id="tagName" formControlName="tag" name="tag"
          placeholder="Tags" maxlength="250" max="250" autocomplete="off">
        <label for="tagName"><b>Tag Name</b></label>
        <i><b>Note:</b> Maximium character allowed 250</i>
      </div>
      <!-- Note -->
      <div class="form-floating mb-3" *ngIf="componentsElement.note && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type="text" class="form-control" formControlName="note" id="note" name="note" 
        placeholder="Enter note" max="1000" maxlength="1000" autocomplete="off">
        <label for="note"><b>Note</b></label>
        <i><b>Note:</b> Maximium character allowed 1000</i>
      </div>
      <!-- Placeholder -->
      <div class="form-floating mb-3" *ngIf="componentsElement.placeholder && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type="text" class="form-control" id="placeholder" formControlName="placeholder" name="placeholder"
          placeholder="Enter Placeholder" max="255" maxlength="255" autocomplete="off">
        <label for="placeholder"><b>Placeholder</b></label>
        <i><b>Note:</b> Maximium character allowed 255</i>
      </div>
      <!-- Units -->
      <div class="form-floating mb-3" *ngIf="componentsElement.unit && !componentsElement.columnData && !componentsElement.cellInfo">
        <select class="form-select" id="unit" formControlName="unit">
          <option selected>--Select--</option>
          <option *ngFor="let item of unitData" [value]="item.unit"><b>{{item.unit}}</b></option>
        </select>
        <label for="dateFormat"><b>Units</b></label>
      </div>
      <!-- Decimal Points -->
      <div class="form-floating mb-3" *ngIf="componentsElement.decimalPoints && !componentsElement.columnData && !componentsElement.cellInfo">
        <select class="form-select" id="decimalPoints" formControlName="decimalPoints">
          <option [ngValue]="null" selected>--Select--</option>
          <option *ngFor="let item of decimalData" [value]="item.id"><b>{{item.name}}</b></option>
        </select>
        <label for="dateFormat"><b>Decimal Points</b></label>
      </div>
      <!-- Default Value -->
      <div class="form-floating mb-3 " *ngIf="componentsElement.defaultVal && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type="number" class="form-control" id="defaultVal" formControlName="defaultVal" name="defaultVal"
          placeholder="Enter Annotated Name" min="0">
        <label for="defaultVal"><b>Default Value</b></label>
      </div>
      <!-- is Formula -->
      <div class="form-floating mb-3" *ngIf="componentsElement.isFormula && !componentsElement.columnData && !componentsElement.cellInfo">
        <div class="form-check-inline">
          <input type="checkbox" class="form-check-input" id="isFormula" formControlName="isFormula" name="isFormula">
          <label for="isFormula">&nbsp;Is Formula Applicable ?</label>
        </div>
      </div>
      <!-- Validation -->
      <div class="row">
        <div class="col-12" *ngIf="componentsElement.required && !componentsElement.columnData && !componentsElement.cellInfo">
          <h5>Validation</h5>
          <hr>
        </div>
        <!-- Alphabet or AlphNumeric -->
        <div class="radio-div d-flex p-2" *ngIf="componentsElement.isAlphabet && !componentsElement.columnData && !componentsElement.cellInfo">
          <div class="form-check" *ngFor="let radioButtonValue of radioButtonData; let i=index">
            <input class="form-check-input" type="radio" formControlName="isAlphabet" name="isAlphabet"
              [value]="radioButtonValue.value">
            <label class="form-check-label" for="flexRadioDefault1">
              {{radioButtonValue.name}} &nbsp;&nbsp;
            </label>
          </div>
          <button class="control-buttons" (click)="resetData('isAlphabet','simpleField')">
            <i class="resetIcon" aria-hidden="true"></i>
          </button>
        </div>
        <!-- Is Required -->
        <div class="form-floating mb-3" *ngIf="componentsElement.required && !componentsElement.columnData && !componentsElement.cellInfo" [hidden]="popupForm.get('isFormula')?.value">
          <div class="form-check-inline">
            <input type="checkbox" class="form-check-input" id="required" formControlName="required" name="required">
            <label for="required">&nbsp;Is required</label>
          </div>
        </div>
        <!-- Character Length -->
        <div class="row" *ngIf="componentsElement.minLength && componentsElement.maxLength && !componentsElement.columnData && !componentsElement.cellInfo">
          <div class="col-6">
            <!-- Minimium Length -->
            <div class="form-floating mb-3" *ngIf="componentsElement.minLength && !componentsElement.columnData && !componentsElement.cellInfo">
              <input type="number" class="form-control" id="minLength" formControlName="minLength" name="minLength"
                placeholder="Enter Minimium Length" autocomplete="off" min="0">
              <label for="minLength"><b>Minimium Length</b></label>
            </div>
          </div>
          <div class="col-6">
            <!-- Maximium Length -->
            <div class="form-floating mb-3" *ngIf="componentsElement.maxLength && !componentsElement.columnData && !componentsElement.cellInfo">
              <input type="number" class="form-control" id="maxLength" formControlName="maxLength" name="maxLength"
                placeholder="Enter Maximium Length" autocomplete="off" min="0">
              <label for="maxLength"><b>Maximium Length</b></label>
            </div>
          </div>
        </div>
        <!-- Number Length -->
        <div class="row" *ngIf="componentsElement.minValue && componentsElement.maxValue && !componentsElement.columnData && !componentsElement.cellInfo">
          <div class="col-6">
            <!-- Minimium Value -->
            <div class="form-floating mb-3" *ngIf="componentsElement.minValue && !componentsElement.columnData && !componentsElement.cellInfo">
              <input type="number" class="form-control" id="minValue" formControlName="minValue" name="minValue"
                placeholder="Enter Minimium Value" autocomplete="off" min="0">
              <label for="minValue"><b>Minimium Value</b></label>
            </div>
          </div>
          <div class="col-6">
            <!-- Maximium Value -->
            <div class="form-floating mb-3" *ngIf="componentsElement.maxValue && !componentsElement.columnData && !componentsElement.cellInfo">
              <input type="number" class="form-control" id="maxValue" formControlName="maxValue" name="maxValue"
                placeholder="Enter Maximium Length" autocomplete="off" min="0">
              <label for="maxValue"><b>Maximium Value</b></label>
            </div>
          </div>
        </div>
        <!-- Date Min/Max -->
        <div class="row" *ngIf="componentsElement.minDate && componentsElement.maxDate && !componentsElement.columnData && !componentsElement.cellInfo">
          <div class="col-6">
            <!-- Min Date -->
            <div class="form-group mb-3" *ngIf="componentsElement.minDate && !componentsElement.columnData && !componentsElement.cellInfo">
              <div class="input-group">
                <input class="form-control" placeholder="Minimium Date" formControlName="minDate" 
                name="minDate" ngbDatepicker #min="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="min.toggle()">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="min.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                  <button class="control-buttons mt-1" (click)="resetDate(true,'simpleField')" type="button">
                    <i class="resetIcon" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                let-disabled="disabled" let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                  [class.text-muted]="date.month !== currentMonth">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
          <!-- <div class="col-2 mt-1" style="text-align-last: center;">
            <button class="control-buttons" (click)="resetDate('simpleField')" type="button">
              <i class="resetIcon" aria-hidden="true"></i>
            </button>
          </div> -->
          <div class="col-6">
            <!-- Max Date -->
            <div class="form-group mb-3" *ngIf="componentsElement.maxDate && !componentsElement.columnData && !componentsElement.cellInfo">
              <div class="input-group">
                <input class="form-control" placeholder="Maximium Date" formControlName="maxDate"
                name="maxDate" ngbDatepicker #max="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="max.toggle()">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="max.toggle()" type="button">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                  <button class="control-buttons mt-1" (click)="resetDate(false,'simpleField')" type="button">
                    <i class="resetIcon" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                let-disabled="disabled" let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                  [class.text-muted]="date.month !== currentMonth">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!-- Options -->
      <div class="row" *ngIf="componentsElement.options && !componentsElement.columnData && !componentsElement.cellInfo">
        <div class="col-12">
          <h5>Options</h5>
          <hr>
        </div>
        <div class="col-12">
          <div formArrayName='options' cdkDropList (cdkDropListDropped)="dropRadio($event)">
            <div *ngFor="let opt of optionFormGroups.controls; let i = index;">
              <div class=" form-group row" [formGroupName]="i" cdkDrag>
                <!--  drag button -->
                <div class="col-sm-1 text-center mt-3">
                  <button class="control-buttons cursorData" cdkDragHandle>
                    <i class="fas fa-bars"></i>
                  </button>
                </div>
                <!-- Name -->
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="name" placeholder="Enter option name" autocomplete="off"
                    max="500" maxlength="500">
                    <label for="name"><b>Name</b></label>
                  </div>
                </div>
                <!-- Value -->
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="value" placeholder="Enter option value" autocomplete="off"
                    max="500" maxlength="500">
                    <label for="value"><b>Value</b></label>
                  </div>
                </div>
                <!-- Code -->
                <div class="col">
                  <div class="form-floating mb-3">
                    <input type="number" min="0" class="form-control" formControlName="code" autocomplete="off"
                      placeholder="Enter option Code">
                    <label for="code"><b>Code</b></label>
                  </div>
                </div>

                <!-- close -->
                <div class="col-sm-1 text-center mt-3">
                  <button class="btn-close" (click)="removeOption(i)"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <button class=" btn btn-outline-primary  btn-block" (click)="addNewOption()"><b>Add
              Option</b></button>
        </div>
      </div>
      <!-- Value -->
      <div class="form-floating mb-3" *ngIf="componentsElement.value && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type="text" class="form-control" formControlName="value" id="value" name="value"
          placeholder="Enter Value" autocomplete="off">
        <label for="value"><b>Value</b></label>
      </div>
      <!-- Formatting -->
      <div formGroupName="formatting" *ngIf="componentsElement.formatting && !componentsElement.columnData && !componentsElement.cellInfo">
        <!-- HeadingSize -->
        <div class="form-floating mb-3">
          <!-- <label><b>Select Style</b></label><br> -->
          <!-- Radio Group -->
          <div class="form-group ">
            <!-- Bold -->
            <div class=" form-check-inline" *ngIf="popupForm?.value.formatting.bold != undefined">
              <input class="form-check-input" type="checkbox" formControlName="bold" name="bold" id="bold" value="bold"
                (change)="onBoldCheckboxChange($event)">&nbsp;
              <label class="form-check-label" for="bold">
                Bold
              </label>
            </div>
            <!-- Italic -->
            <div class="form-check-inline" *ngIf="popupForm?.value.formatting.italic != undefined">
              <input class="form-check-input" type="checkbox" formControlName="italic" name="italic" id="italic"
                value="italic" (change)="onItalicCheckboxChange($event)">&nbsp;
              <label class="form-check-label" for="italic">
                Italic
              </label>
            </div>
            <!-- Underline -->
            <div class="form-check-inline" *ngIf="popupForm?.value.formatting.underline != undefined">
              <input class="form-check-input" type="checkbox" formControlName="underline" name="underline"
                id="underline" value="underline" (change)="onUnderlineCheckboxChange($event)">&nbsp;
              <label class="form-check-label" for="underline">
                Underline
              </label>
            </div>
            <!-- Alignment -->
            <div class="form-floating mb-3" *ngIf="popupForm?.value.formatting.alignment != undefined">
              <select class="form-select" id="alignment" formControlName="alignment">
                <option *ngFor="let item of alignment" [value]="item"><b>{{item}}</b></option>
                <!-- <option [value]=""><b>center</b></option> -->
              </select>
              <label for="alignment"><b>Select Alignment</b></label>
            </div>
            <!-- Color -->
            <div class="form-floating mb-3" *ngIf="popupForm?.value.formatting.color != undefined">
              <input type="color" class="form-control" formControlName="color" id="color" name="color"
                placeholder="Enter Label">
              <label for="color"><b>Select Color</b></label>
            </div>
            <!-- HeadingSize -->
            <div class="form-floating mb-3" *ngIf="popupForm?.value.formatting.headingSize != undefined">
              <select class="form-select" aria-selected="true" id="headingSize" formControlName="headingSize">
                <option [ngValue]="null" selected>--Select--</option>
                <option *ngFor="let item of head" [value]="item"><b>{{item}}</b></option>
              </select>
              <label for="headingSize"><b>Heading Size</b></label>
            </div>
            <!-- Divider -->
            <div class="form-floating mb-3" *ngIf="popupForm?.value.formatting.divider != undefined">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="divider" formControlName="divider">
                <label class="form-check-label" for="divider"><b>Show Divider</b></label>
              </div>
            </div>
            <!-- Date Format -->
            <div class="form-floating mb-3" *ngIf="popupForm?.value.formatting.dateFormat != undefined && !componentsElement.columnData && !componentsElement.cellInfo">
              <select class="form-select" id="dateFormat" formControlName="dateFormat">
                <option [ngValue]="''" selected>--Select--</option>
                <option *ngFor="let item of dateFormat" [value]="item.format"><b>{{item.format}}</b></option>
              </select>
              <label for="dateFormat"><b>Date Format</b></label>
            </div>
            <!-- Hour Format -->
            <div class="mb-3" *ngIf="popupForm?.value.formatting.hourFormat != undefined && !componentsElement.columnData && !componentsElement.cellInfo">
              <label><b>Select Time Format</b></label>
              <div class="form-floating ">
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                    id="12hours" value="12 hrs"> &nbsp;
                  <label class="form-check-label" for="12hours">
                    12 Hrs
                  </label>
                </div>
                <div class="form-check-inline">
                  <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                    id="24hours" value="24 hrs">&nbsp;
                  <label class="form-check-label" for="24hours">
                    24 Hrs
                  </label>
                </div>
              </div>
            </div>
            <!-- Time Format -->
            <div class="mb-3" *ngIf="popupForm?.value.formatting.hourFormat && !componentsElement.columnData && !componentsElement.cellInfo">
              <!-- <select class="form-select" id="timeFormat" formControlName="timeFormat">
                <option selected disabled>Select Time Format</option>
                <option *ngFor="let item of timeFormat" [value]="item"><b>{{item}}</b></option>
              </select> -->
              <label for="timeFormat"><b>Time Format</b></label>
              <div class="form-floating">
                <div class="form-check-inline">
                  <input type="checkbox" id="hour" [checked]="popupForm.value.formatting.hour == 'true'" formControlName="hour"> &nbsp;
                  <label class="form-check-label">
                    Hour
                  </label>
                </div>
                <div class="form-check-inline">
                  <input type="checkbox" id="minute" [checked]="popupForm.value.formatting.minute == 'true'" formControlName="minute">&nbsp;
                  <label class="form-check-label">
                    Minute
                  </label>
                </div>
                <div class="form-check-inline">
                  <input type="checkbox" id="second" [checked]="popupForm.value.formatting.second == 'true'" formControlName="second"> &nbsp;
                  <label class="form-check-label">
                    Second
                  </label>
                </div>
                <div class="form-check-inline" *ngIf="popupForm?.value.formatting.hourFormat == '12 hrs'">
                  <input type="checkbox" id="midDay" [checked]="popupForm.value.formatting.midDay == 'true'" formControlName="midDay"> &nbsp;
                  <label class="form-check-label">
                    AM/PM
                  </label>
                </div>
              </div>
            </div>
            <!-- Is Default -->
            <div class=" mb-3" *ngIf="componentsElement.imagePath && popupForm?.value.formatting.isdefault != undefined && !componentsElement.columnData && !componentsElement.cellInfo">
                <input type="hidden" formControlName="isdefault" >
                <div class="row" name="isdefault" id="isdefault">
                  <div class="col-sm-12">
                    <label for="image"><b>Image</b></label>
                    <span style="color:red;" *ngIf="message">{{message}}</span>
                    <input #file size="4000" type="file" accept="image/png, image/jpeg" class="form-control" id="image" name="image" (change)="preview(file.files)">
                  </div>
                  <div class="col" *ngIf="imgURL">
                    <br>
                    <label for=""><b>Preview</b></label> &nbsp;&nbsp;
                    <img [src]="imgURL" alt="Image-Here" height="50">
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Link -->
      <div class="form-floating mb-3" *ngIf="componentsElement.link && !componentsElement.columnData && !componentsElement.cellInfo">
        <input type=" text" class="form-control" formControlName="link" id="link" name="link" placeholder="Enter Link" (change)="displayLinkFormat()" autocomplete="off">
        <label for="link"><b>Link</b></label>
      </div>
      <!-- Table Heading -->
      <div class="row" *ngIf="componentsElement.columnData && componentsElement.label && !componentsElement.cellInfo">
        <div class="col-sm-9">
          <div class="form-floating mb-3" *ngIf="componentsElement.label">
            <input type="text" class="form-control" formControlName="label" id="label" name="label" autocomplete="off"
              placeholder="Enter Table Heading" required max="255" maxlength="255">
            <label for="label"><b>Table Heading</b></label>
            <i><b>Note:</b> Maximium character allowed 255</i>
          </div>
        </div>
        <div class="col-sm-3 text-center">
          <button title="Add Columns" class="btn btn-outline-primary mt-2" (click)="addNewColumnData()">
            <b>Add Columns</b>
          </button>
        </div>
      </div>
      <!-- Multi Row Design View -->
      <div class="row" *ngIf="componentsElement.columnData && componentsElement.multiRowView">
        <div class="col-12" *ngIf="componentsElement.multiRowView">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><b>Multi Row Design</b></label>
            <div class="col-sm-9">
              <div class="form-check" *ngFor="let item of multiRowDesign;let i = index">
                <input id="item.name" formControlName="multiRowView" class="form-check-input" type="radio"
                [value]="item.id">
                <label for="item.name" class="form-check-label">{{item.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="example-box" *ngIf="componentsElement.columnData && !componentsElement.cellInfo" cdkDropList [cdkDropListData]="componentsElement.columnData" cdkDragBoundary=".example-box"
        (cdkDropListDropped)="drop($event)" [cdkDropListSortingDisabled]="false">
        <div formArrayName='columnData' *ngIf="componentsElement.columnData && !componentsElement.cellInfo">
          <div cdkDrag *ngFor="let opt of getColumnData.controls; let i = index;">
            <div class="col-12" [formGroupName]="i">
              <div ngbAccordion #accordion="ngbAccordion">
                <div ngbAccordionItem="{{i}}">
                  <div ngbAccordionHeader>
                    <button class="accordion-button control-buttons" ngbAccordionToggle>
                      <div class="col-1">
                        <button class="control-buttons" cdkDragHandle>
                          <i class="fa fa-bars" aria-hidden="true"></i>
                        </button>
                      </div>
                      <div class="col-10 wordWrap">
                        <span *ngIf="!onChangeColumnHeading(i)"><b>Column-{{i+1}}</b></span>
                        <span *ngIf="onChangeColumnHeading(i)"><b>{{onChangeColumnHeading(i)}}</b></span>
                      </div>
                      <div class="col text-end">
                        <button class="control-buttons" title="Copy Column" (click)="copyMultiRowColumn(i)">
                          <span class="copyIcon"></span>
                        </button>
                      </div>&nbsp;
                      <div class="col text-end">
                        <button class="btn-close" title="Remove Column" (click)="removeColumnData(i)"></button>
                      </div>
                    </button>
                  </div>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <div class="row">
                          <!-- Column Heading -->
                          <div class="col">
                            <div class="form-floating mb-3">
                              <div class="form-floating mb-3">
                                <input type="text" class="form-control" placeholder="Column Heading"
                                id="colHeading" formControlName="colHeading" (change)="onChangeColumnHeading(i)"
                                max="500" maxlength="500">
                                <label for="colHeading"><b>Column Heading</b></label>
                                <i><b>Note:</b> Maximium character allowed 500</i>
                              </div>
                            </div>
                          </div>
                          <!-- Select Type -->
                          <div class="col" *ngIf="getColumnData.controls[i].value.colHeading">
                            <div class="form-floating mb-3">
                              <select class="form-select" (change)="onTypeSelected($event.target,i)" formControlName="type">
                                <option [ngValue]="null" selected>--Select--</option>
                                <option *ngFor="let item of selectType" [value]="item.type">{{item.type}}</option>
                              </select>
                              <label for="floatingSelect">Select Type</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Annotated Name -->
                          <div class="col">
                            <div *ngIf="opt.get('annotatedName')">
                              <div class="form-floating mb-3 ">
                                <input type="text" class="form-control" id="annotatedName" formControlName="annotatedName" name="annotatedName"
                                placeholder="Enter Annotated Name" max="500" maxlength="500">
                                <label for="annotatedName"><b>Annotated Name</b></label>
                                <i><b>Note:</b> Maximium character allowed 500</i>
                              </div>
                            </div>
                          </div>
                           <!-- Tag Name -->
                           <div class="col">
                            <div *ngIf="opt.get('tag')">
                              <div class="form-floating mb-3 ">
                                <input type="text" class="form-control" id="tagName" formControlName="tag" name="tag"
                                placeholder="Enter Tag Name" max="250" maxlength="250">
                                <label for="tagName"><b>Tag Name</b></label>
                                <i><b>Note:</b> Maximium character allowed 250</i>
                              </div>
                            </div>
                          </div>
                          <!-- Placeholder -->
                          <div class="col" *ngIf="opt.get('placeholder')">
                            <div class="form-floating mb-3">
                              <input type="text" class="form-control" id="placeholder" formControlName="placeholder" name="placeholder"
                              placeholder="Enter Placeholder" max="200" maxlength="200">
                              <label for="placeholder"><b>Placeholder</b></label>
                              <i><b>Note:</b> Maximium character allowed 200</i>
                            </div>
                          </div>
                          <!-- Note -->
                          <div class="col" *ngIf="opt.get('note') && opt.get('placeholder') == undefined">
                            <div class="form-floating mb-3">
                              <input type="text" class="form-control" id="note" formControlName="note" name="note"
                              placeholder="Enter Note" max="1000" maxlength="1000">
                              <label for="note"><b>Note</b></label>
                              <i><b>Note:</b> Maximium character allowed 1000</i>
                            </div>
                          </div>
                        </div>
                        <!-- Note -->
                        <div class="row" *ngIf="opt.get('note') && ![3,6,7,8,12,13].includes(opt.get('dataTypeId')?.value)">
                          <div class="col">
                            <div class="form-floating mb-3">
                              <input type="text" class="form-control" id="note" formControlName="note" name="note"
                              placeholder="Enter Note" max="1000" maxlength="1000">
                              <label for="note"><b>Note</b></label>
                              <i><b>Note:</b> Maximium character allowed 1000</i>
                            </div>
                          </div>
                        </div>
                        <!-- Number Design -->
                        <div class="row">
                          <!-- Decimal Points -->
                          <div class="col" *ngIf="opt.get('decimalPoints')">
                            <div class="form-floating mb-3">
                              <select class="form-select" id="decimalPoints" formControlName="decimalPoints">
                                <option [ngValue]="null" selected>--Select--</option>
                                <option *ngFor="let item of decimalData" [value]="item.id"><b>{{item.name}}</b></option>
                              </select>
                              <label for="decimalPoints"><b>Decimal Points</b></label>
                            </div>
                          </div>
                          <!-- Unit -->
                          <div class="col" *ngIf="opt.get('unit')">
                            <div class="form-floating mb-3">
                              <select class="form-select" id="unit" formControlName="unit">
                                <option selected [ngValue]="null">Select Unit</option>
                                <option *ngFor="let item of unitData" [value]="item.unit"><b>{{item.unit}}</b></option>
                              </select>
                              <label for="unit"><b>Units</b></label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col" *ngIf="opt.get('defaultVal')">
                            <!-- Default Value -->
                            <div class="form-floating mb-3 ">
                              <input type="number" class="form-control" id="defaultVal" formControlName="defaultVal" name="defaultVal"
                              placeholder="Enter Annotated Name" min="0">
                              <label for="defaultVal"><b>Default Value</b></label>
                            </div>
                          </div>
                          <!-- is Formula -->
                          <div class="col" *ngIf="opt.get('isFormula')">
                            <div class="form-floating mb-3 mt-3">
                              <div class="form-check-inline">
                                <input type="checkbox" class="form-check-input" id="isFormula" formControlName="isFormula" name="isFormula">
                                <label for="isFormula">&nbsp;Is Formula Applicable ?</label>
                              </div>
                            </div>  
                          </div>
                        </div>
                        <!-- Note -->
                        <div class="row" *ngIf="opt.get('note') && opt.get('dataTypeId')?.value == 3">
                          <div class="col">
                            <div class="form-floating mb-3">
                              <input type="text" class="form-control" id="note" formControlName="note" name="note"
                              placeholder="Enter Note" max="1000" maxlength="1000">
                              <label for="note"><b>Note</b></label>
                              <i><b>Note:</b> Maximium character allowed 1000</i>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12" *ngIf="opt.get('required')">
                            <h5>Validation</h5>
                            <hr>
                          </div>
                          <!-- Is Required -->
                          <div class="form-floating mb-3" *ngIf="opt.get('required')" [hidden]="opt.get('isFormula')?.value">
                            <div class="form-check-inline">
                              <input type="checkbox" class="form-check-input" id="required" formControlName="required" name="required">
                              <label for="required">&nbsp;Is required</label>
                            </div>
                          </div>
                          <!-- Alphabet or AlphNumeric -->
                          <div class="radio-div d-flex p-2" *ngIf="opt.get('isAlphabet')">
                            <div class="form-check mx-3" *ngFor="let radioButtonValue of radioButtonData; let i = index">
                              <input class="form-check-input" type="radio" formControlName="isAlphabet" id="radioButtonValue.name"
                                [value]="radioButtonValue.value">
                              <label class="form-check-label" for="flexRadioDefault1">
                                {{radioButtonValue.name}}&nbsp;
                              </label>
                            </div>
                            <button class="control-buttons" (click)="resetData('isAlphabet','multiRowTableField',opt)">
                              <i class="resetIcon" aria-hidden="true"></i>
                            </button>
                          </div>
                          <!-- Number Length -->
                          <div class="row" *ngIf="opt.get('minValue') && opt.get('maxValue')">
                            <div class="col" *ngIf="opt.get('minValue')">
                              <!-- Minimium Value -->
                              <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="minValue" formControlName="minValue" name="minValue"
                                  placeholder="Enter Minimium Value" min="0">
                                <label for="minValue"><b>Minimium Value</b></label>
                              </div>
                            </div>
                            <div class="col" *ngIf="opt.get('maxValue')">
                              <!-- Maximium Value -->
                              <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="maxValue" formControlName="maxValue" name="maxValue"
                                  placeholder="Enter Maximium Length" min="0">
                                <label for="maxValue"><b>Maximium Value</b></label>
                              </div>
                            </div>
                          </div>
                          <!-- Date Min/Max -->
                          <div class="row" *ngIf="opt.get('minDate') && opt.get('maxDate')">
                            <div class="col-6" *ngIf="opt.get('minDate')">
                              <!-- Min Date -->
                              <div class="form-group mb-3">
                                <div class="input-group">
                                  <input class="form-control" placeholder="Minimium Date" formControlName="minDate" 
                                  name="minDate" ngbDatepicker #colMin="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="colMin.toggle()">
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="colMin.toggle()" type="button">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </button>
                                    <button class="control-buttons mt-1" (click)="resetDate(true,'multiRowTableField',opt)" type="button">
                                      <i class="resetIcon" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                                <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                  let-disabled="disabled" let-focused="focused">
                                  <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                    [class.text-muted]="date.month !== currentMonth">
                                    {{ date.day }}
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                            <!-- <div class="col-2 mt-1" style="text-align-last: center;">
                              <button class="control-buttons" (click)="resetDate('multiRowTableField',opt)" type="button">
                                <i class="resetIcon" aria-hidden="true"></i>
                              </button>
                            </div> -->
                            <div class="col-5" *ngIf="opt.get('maxDate')">
                              <!-- Max Date -->
                              <div class="form-group mb-3">
                                <div class="input-group">
                                  <input class="form-control" placeholder="Maximium Date" formControlName="maxDate"
                                  name="maxDate" ngbDatepicker #colMax="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="colMax.toggle()">
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="colMax.toggle()" type="button">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </button>
                                    <button class="control-buttons mt-1" (click)="resetDate(false,'multiRowTableField',opt)" type="button">
                                      <i class="resetIcon" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                                <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                  let-disabled="disabled" let-focused="focused">
                                  <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                    [class.text-muted]="date.month !== currentMonth">
                                    {{ date.day }}
                                  </span>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Options -->
                          <div class="row" *ngIf="opt.get('options')?.value.length > 0">
                            <div class="col-12">
                              <h5>Options</h5>
                              <hr>
                            </div>
                            <div class="col-12">
                              <div formArrayName="options" cdkDropList (cdkDropListDropped)="dropRadio($event,i)">
                                <div *ngFor="let optData of optionFormGroupsInColumn(i); let ind = index;" cdkDrag>
                                  <div class=" form-group row" [formGroupName]="ind">
                                    <!--  drag button -->
                                    <div class="col-sm-1 text-center mt-3">
                                      <button class="control-buttons cursorData" cdkDragHandle>
                                        <i class="fas fa-bars"></i>
                                      </button>
                                    </div>

                                    <!-- Name -->
                                    <div class="col">
                                      <div class="form-floating mb-3">
                                        <input type="text" class="form-control" formControlName="name" placeholder="Enter option name" max="500" maxlength="500">
                                        <label for="name"><b>Name</b></label>
                                      </div>
                                    </div>
                                    <!-- Value -->
                                    <div class="col">
                                      <div class="form-floating mb-3">
                                        <input type="text" class="form-control" formControlName="value" placeholder="Enter option value" max="255" maxlength="255">
                                        <label for="value"><b>Value</b></label>
                                      </div>
                                    </div>
                                    <!-- Code -->
                                    <div class="col">
                                      <div class="form-floating mb-3">
                                        <input type="number" min="0" class="form-control" formControlName="code"
                                          placeholder="Enter option Code">
                                        <label for="code"><b>Code</b></label>
                                      </div>
                                    </div>
                                    <!-- close -->
                                    <div class="col-sm-1 text-center mt-3">
                                      <button class="btn-close" (click)="removeOptionsInColumn(i,ind)"></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <button class=" btn btn-outline-primary  btn-block" (click)="addNewOptionInColumn(i);">
                                <b>
                                  AddOption
                                </b>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <!-- <div class="col-6"> -->
                            <!-- Formatting -->
                            <div formGroupName="formatting" *ngIf="opt.get('formatting')">
                              <!-- HeadingSize -->
                              <div class="form-floating mb-3">
                                <!-- Radio Group -->
                                <div class="form-group ">
                                  <!-- Date Format -->
                                  <div class="form-floating mb-3" *ngIf="opt.get('formatting')?.value.dateFormat != undefined">
                                    <select class="form-select" id="dateFormat" formControlName="dateFormat">
                                      <option [ngValue]="''" selected>--Select--</option>
                                      <option *ngFor="let item of dateFormat" [value]="item.format"><b>{{item.format}}</b></option>
                                    </select>
                                    <label for="dateFormat"><b>Date Format</b></label>
                                  </div>
                                  <!-- Hour Format -->
                                  <div class="mb-3" *ngIf="opt.get('formatting')?.value.hourFormat != undefined">
                                    <label><b>Select Time Format</b></label>
                                    <div class="form-floating ">
                                      <div class="form-check-inline">
                                        <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                                          id="12hours" value="12 hrs"> &nbsp;
                                        <label class="form-check-label" for="12hours">
                                          12 Hrs
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                                          id="24hours" value="24 hrs">&nbsp;
                                        <label class="form-check-label" for="24hours">
                                          24 Hrs
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Time Format -->
                                  <div class="mb-3" *ngIf="opt.get('formatting')?.value.hourFormat != undefined">
                                    <label for="timeFormat"><b>Time Format</b></label>
                                    <div class="form-floating">
                                      <div class="form-check-inline">
                                        <input type="checkbox" id="hour" formControlName="hour"
                                        [checked]="opt.get('formatting')?.value.hour == 'true'"> &nbsp;
                                        <label class="form-check-label">
                                          Hour
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <input type="checkbox" id="minute" formControlName="minute"
                                        [checked]="opt.get('formatting')?.value.minute == 'true'">&nbsp;
                                        <label class="form-check-label">
                                          Minute
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <input type="checkbox" id="second" formControlName="second"
                                        [checked]="opt.get('formatting')?.value.second == 'true'"> &nbsp;
                                        <label class="form-check-label">
                                          Second
                                        </label>
                                      </div>
                                      <div class="form-check-inline" *ngIf="opt.value.formatting.hourFormat == '12 hrs'">
                                        <input type="checkbox" id="midDay" formControlName="midDay"
                                        [checked]="opt.get('formatting')?.value.midDay == 'true'"> &nbsp;
                                        <label class="form-check-label">
                                          AM/PM
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          <!-- </div> -->
                        </div>
                      </ng-template>
                      </div>
                    </div>
                </div>
              </div>
            </div><br>
          </div>
        </div>
      </div>
      <!-- Static Table Component -->
      <div *ngIf="componentsElement.label && componentsElement.cellInfo">
        <div class="row">
          <div class="col">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" formControlName="label" id="label" name="label"
                placeholder="Enter Table Heading" required max="255" maxlength="255">
              <label for="label"><b>Table Heading</b></label>
              <i><b>Note:</b> Maximium character allowed 255</i>
            </div>
          </div>
        </div>
        <div class="row" formGroupName="formatting" *ngIf="componentsElement.formatting && !componentsElement.columnData && componentsElement.cellInfo">
          <div class="col">
            <div class="form-floating mb-3">
              <input type="number" class="form-control" formControlName="row" id="row" name="row"
                placeholder="Enter No. of Rows" required min="0" [readOnly]="popupForm.get('isRowColumnDataAdd')?.value"
                (change)="generateTable()">
              <label for="label"><b>Rows</b></label>
            </div>
          </div>
          <div class="col">
            <div class="form-floating mb-3">
              <input type="number" class="form-control" formControlName="column" id="column" name="column"
                placeholder="Enter No. of Columns" required min="0" [readOnly]="popupForm.get('isRowColumnDataAdd')?.value"
                (change)="generateTable()">
              <label for="label"><b>Columns</b></label>
            </div>
          </div>
          <div class="col-2 mt-2">
            <!-- <button class="control-buttons" title="Generate" (click)="generateTable()" [disabled]="popupForm.get('isRowColumnDataAdd')?.value">
              <span class="plusIcon"></span>
            </button> &nbsp; -->
            <button class="control-buttons" title="Reset" (click)="resetTable()">
              <span class="resetIcon"></span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 table-responsive" style="overflow-y: auto;">
            <table class="table table-sm table-bordered" formArrayName="cellInfo">
              <tbody cdkDropList (cdkDropListDropped)="dropRow($event)">
                <tr cdkDrag cdkDragLockAxis="y" [cdkDragPreviewContainer]="'parent'" 
                  *ngFor="let row of getTableDataField.controls;let rowIndex = index" 
                  [formArrayName]="rowIndex">
                  <td [attr.colspan]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.colspan" 
                    [attr.rowspan]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.rowspan"
                    *ngFor="let column of getCellInfoData(rowIndex).controls;let columnIndex = index" 
                    [hidden]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isHidden"
                    [formArrayName]="columnIndex">
                    <div *ngFor="let cellArray of getCellInfoInnerArray(rowIndex,columnIndex).controls;let cellArrayIndex = index" 
                      [formGroupName]="cellArrayIndex">
                      <!-- Adding Fields -->
                      <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved == undefined && rowIndex === 0 && columnIndex === 0">
                        <!-- Comment Code : <p class="text-center mt-1">#</p> -->
                      </div>
                      <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved == undefined && (rowIndex !== 0 || columnIndex !== 0)">
                        <div class="row">
                          <div class="col text-center mt-1">
                            <button class="control-buttons cursorData" *ngIf="columnIndex == 0 && rowIndex != 1"
                              title="Drag Row" cdkDragHandle>
                              <span class="dragBarIcon"></span>
                            </button>&nbsp;
                            <button class="control-buttons" *ngIf="columnIndex == 0" 
                              title="Add rows to bottom"
                              (click)="addRowColumn(rowIndex,columnIndex,'row')">
                              <span class="plusIcon"></span>
                            </button>
                            <button class="control-buttons" *ngIf="rowIndex == 0" 
                              title="Add columns to right"
                              (click)="addRowColumn(rowIndex,columnIndex,'column')">
                              <span class="plusIcon"></span>
                            </button>&nbsp;
                            <button class="control-buttons" *ngIf="columnIndex == 0 && rowIndex != 1"
                              title="Copy rows to bottom"
                              (click)="copyRowColumn(rowIndex,columnIndex,'row')"
                              [hidden]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isRowSpan || 
                              getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isColSpan">
                              <span class="copyCellIcon"></span>
                            </button>
                            <button class="control-buttons" *ngIf="rowIndex == 0"
                              title="Copy columns to right"
                              (click)="copyRowColumn(rowIndex,columnIndex,'column')"
                              [hidden]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isColSpan || 
                              getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isRowSpan">
                              <span class="copyCellIcon"></span>
                            </button>&nbsp;
                            <button class="control-buttons" *ngIf="columnIndex == 0 && rowIndex != 1"
                              title="Delete row"
                              (click)="deleteRowColumn(rowIndex,columnIndex,'row')">
                              <span class="deleteIcon"></span>
                            </button>
                            <button class="control-buttons" *ngIf="rowIndex == 0"
                              title="Delete column"
                              (click)="deleteRowColumn(rowIndex,columnIndex,'column')">
                              <span class="deleteIcon"></span>
                            </button>
                            <!-- <button class="control-buttons cursorData" *ngIf="rowIndex == 0"
                              title="Drag Column" cdkDragHandle>
                              <span class="dragIcon"></span>
                            </button> -->
                          </div>
                        </div>
                      </div>
                      <!-- 1st Row 1st Column Label Design -->
                      <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved != undefined && ( rowIndex === 1 || columnIndex === 1)">
                        <!-- Input Data -->
                        <div class="col" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="label" name="label" formControlName="label" placeholder="Enter Label" 
                              max="255" maxlength="255">
                            <label for="label"><b>Label</b></label>
                          </div>
                        </div>
                        <!-- Output Data -->
                        <div class="col-12" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved">
                          <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[11].id">
                            <label>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.label}}</label>
                          </div>
                        </div>
                        <!-- Buttons -->
                        <!-- <div class="col-2 d-flex">
                          <button class="control-buttons" title="Save Label" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved" (click)="saveLabel(rowIndex,columnIndex,cellArrayIndex)">
                            <i class="saveIcon"></i>
                          </button>
                          <button class="control-buttons" title="Update Label" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved" (click)="updateLabel(rowIndex,columnIndex,cellArrayIndex)">
                            <i class="updateIcon"></i>
                          </button>
                          <button class="control-buttons" title="Close Label" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isEdited" (click)="closeLabel(rowIndex,columnIndex,cellArrayIndex)">
                            <i class="btn-close"></i>
                          </button>
                        </div> -->
                      </div>
                      <!-- Three Dot Options -->
                      <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved != undefined && !getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved && rowIndex != 1 && columnIndex != 1">
                        <div class="col-10">
                          <button style="width: 100%;" class="btn btn-outline-secondary" 
                            *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked" 
                            (click)="generateCellForm(rowIndex,columnIndex,cellArrayIndex)">
                            <span class="plusIcon"></span>
                          </button>
                        </div>
                        <div class="col-2" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked">
                          <div ngbDropdown>
                            <button type="button" class="control-buttons mt-1 remove-caret" ngbDropdownToggle>
                              <span class="threeDotVerticalIcon"></span>
                            </button>
                            <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan == null && getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                              <!-- <button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                [hidden]="columnIndex == 1">
                                <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                              </button> -->
                              <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1)">
                                <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                              </button>
                              <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                [hidden]="rowIndex == (getTableDataField.length - 1)">
                                <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                              </button>
                              <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                <span class="splitRow"></span> &nbsp; Split row
                              </button>
                              <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                <span class="splitColumn"></span> &nbsp; Split column
                              </button>
                            </div>
                            <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan != null || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                              <!-- <button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                [hidden]="columnIndex == 1 || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                              </button> -->
                              <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                              </button>
                              <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                [hidden]="rowIndex == (getTableDataField.length - 2) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                                <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                              </button>
                              <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                <span class="splitRow"></span> &nbsp; Split row
                              </button>
                              <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                <span class="splitColumn"></span> &nbsp; Split column
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Output Showing Data -->
                      <div class="container">
                        <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved && rowIndex != 1 && columnIndex != 1">
                          <div class="col-10">
                            <!-- TextView -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[0].id">
                              <div class="row">
                                <input class="form-control" type="text" 
                                [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''"
                                [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0"
                                [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 999999999">
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <label>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</label>
                              </div>
                            </div>
                            <!-- TextArea -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[1].id">
                              <div class="row">
                                <textarea class="form-control" [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                                [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" autocomplete="off"
                                [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                                [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                                [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0"
                                [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 999999999"></textarea>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined" class="row ">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            <!-- Number -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[2].id">
                              <div class="row">
                                <div class="col">
                                  <input type="number" class="form-control" [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                                  [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" autocomplete="off"
                                  [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                                  [disabled]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isFormula != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isFormula : false" 
                                  [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                                  [step]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.decimalPoints != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.decimalPoints : 0" 
                                  [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                  [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999" 
                                  [min]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                  [max]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999">
                                </div>
                                <div class="col-sm-2 mt-2" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.unit != undefined">
                                  <span><b>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.unit}}</b></span>
                                </div>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            <!-- Email -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[3].id">
                              <div class="row">
                                <div class="col">
                                  <input type="email" class="form-control" 
                                  [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                                  [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName"
                                  [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                                  [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                                  [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                  [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999" 
                                  [min]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                  [max]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999">
                                </div>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            <!-- Password -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[4].id">
                              <div class="row">
                                <div class="col">
                                  <input type="password" class="form-control" 
                                  [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                                  [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName"
                                  [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                                  [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                                  [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                  [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999" 
                                  [min]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                  [max]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999">
                                </div>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            <!-- Checkbox -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[5].id">
                              <div class="row">
                                <div class="form-check" *ngFor="let option of getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.options;let check = index">
                                  <input id="option.name" class="form-check-input" type="checkbox" [name]="'check-'+check"
                                  [value]="option.value" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                  <label for="option.name" class="form-check-label">{{option.name}}</label>
                                </div>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            <!-- Radiobutton -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[6].id">
                              <div class="row">
                                <div class="form-check" *ngFor="let option of getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.options;let radio = index">
                                  <input id="option.name" class="form-check-input" type="radio" [name]="'radio-'+radio"
                                  [value]="option.value" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                  <label for="option.name" class="form-check-label">{{option.name}}</label>
                                </div>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            <!-- Selectionbox -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[7].id">
                              <div class="row">
                                <select class="form-select" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                  <option [ngValue]="null" selected>Select</option>
                                  <option *ngFor="let option of getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.options" [value]="option.name">
                                    {{option.name}}
                                  </option>
                                </select>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            <!-- Sub-Heading -->
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                              <label class="col-form-label col-sm-12 " 
                              [style.text-decoration]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.underline != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.underline : ''"
                              [style.font-style]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.italic != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.italic : ''" 
                              [style.font-weight]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.bold != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.bold : ''"
                              [style.color]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.color != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.color : ''" 
                              [style.text-align]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.alignment != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.alignment : ''">
                              {{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.label}}</label>
                            </div>
                            <!-- Date -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                              <div class="row">
                                <div class="form-group" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isUnknownDate">
                                  <div class="input-group">
                                    <input class="form-control" 
                                    [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.dateFormat != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.dateFormat : ''" 
                                    [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName"
                                    ngbDatepicker #d="ngbDatepicker" 
                                    [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false"
                                    [readOnly]="true" [dayTemplate]="customDay" (click)="d.toggle()">
                                    <div class="input-group-append">
                                      <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                    let-disabled="disabled" let-focused="focused">
                                    <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                      [class.text-muted]="date.month !== currentMonth">
                                      {{ date.day }}
                                    </span>
                                  </ng-template>
                                </div>
                                <div class="form-group" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isUnknownDate">
                                  <div class="input-group">
                                    <div class="form-floating mb-3">
                                      <select class="form-select" id="date" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected value="UK">UK</option>
                                        <option *ngFor="let item of dateData" [value]="item"><b>{{item}}</b></option>
                                      </select>
                                      <label for="date"><b>Date </b></label>
                                    </div>&nbsp;&nbsp;
                                    <div class="form-floating mb-3">
                                      <select class="form-select" id="month" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option value="UNK" selected>UNK</option>
                                        <option *ngFor="let item of monthData" [value]="item"><b>{{item}}</b></option>
                                      </select>
                                      <label for="month"><b>Month </b></label>
                                    </div>&nbsp;&nbsp;
                                    <div class="form-floating mb-3">
                                      <select class="form-select" id="year" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option value="UNKK" selected>UNKK</option>
                                        <option *ngFor="let item of yearData" [value]="item"><b>{{item}}</b></option>
                                      </select>
                                      <label for="year"><b>Year </b></label>
                                    </div>&nbsp;&nbsp;
                                  </div>
                                </div>
                              </div>
                              <i *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              <br>
                              <div class="row">
                                <div class="form-floating mb-3">
                                  <div class="form-check-inline">
                                    <input class="form-check-input" type="checkbox" formControlName="isUnknownDate" name="isUnknownDate" id="isUnknownDate"
                                    value="isUnknownDate" (change)="checkDateIsUnkown($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                    <label for="isUnknownDate">
                                      <b>Unknown Date?</b>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Time -->
                            <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[10].id">
                              <div class="row">
                                <div class="form-group" [ngSwitch]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hourFormat">
                                  <!-- 12 Hrs -->
                                  <div class="row" *ngSwitchCase="'12 hrs'">
                                    <!-- hours -->
                                    <div class="col" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == true || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == 'true'">
                                      <div class="form-floating">
                                        <select class="form-select" id="hours" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                          <option selected disabled>Select</option>
                                          <option value="UK" selected>UK</option>
                                          <option *ngFor="let hr of hours12" [value]="hr">{{hr}}</option>
                                        </select>
                                        <label for="hours">Hrs </label>
                                      </div>
                                    </div>
                                    <!-- minutes -->
                                    <div class="col" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == true">
                                      <div class="form-floating">
                                        <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                          <option selected disabled>Select</option>
                                          <option value="UM" selected>UM</option>
                                          <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                                        </select>
                                        <label for="minutes">Min </label>
                                      </div>
                                    </div>
                                    <!-- Seconds -->
                                    <div class="col" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == true">
                                      <div class="form-floating">
                                        <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                          <option selected disabled>Select</option>
                                          <option value="UN" selected>UN</option>
                                          <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                                        </select>
                                        <label for="minutes">Sec </label>
                                      </div>
                                    </div>
                                    <!-- midday -->
                                    <div class="col" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hourFormat == '12 hrs' && getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.midDay == true || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.midDay == 'true'">
                                      <div class="form-floating">
                                        <select class="form-select" id="midday" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                          <option selected disabled>Select</option>
                                          <option value="UM" selected>UM</option>
                                          <option *ngFor="let md of midday" [value]="md">{{md}}</option>
                                        </select>
                                        <label for="midday">AM/PM </label>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- 24 Hrs -->
                                  <div class="row" *ngSwitchCase="'24 hrs'">
                                    <!-- hours -->
                                    <div class="col" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == true">
                                      <div class="form-floating">
                                        <select class="form-select" id="hours" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                          <option selected disabled>Select</option>
                                          <option value="UK" selected>UK</option>
                                          <option *ngFor="let hr of hours24" [value]="hr">{{hr}}</option>
                                        </select>
                                        <label for="hours">Hrs</label>
                                      </div>
                                    </div>
                                    <!-- minutes -->
                                    <div class="col" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == true || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == 'true'">
                                      <div class="form-floating">
                                        <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                          <option selected disabled>Select</option>
                                          <option value="UM" selected>UM</option>
                                          <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                                        </select>
                                        <label for="minutes">Min </label>
                                      </div>
                                    </div>
                                    <!-- Second -->
                                    <div class="col" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == true">
                                      <div class="form-floating">
                                        <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                          <option selected disabled>Select</option>
                                          <option value="UN" selected>UN</option>
                                          <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                                        </select>
                                        <label for="minutes">Sec </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <i *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                            </div>
                            <!-- Label -->
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[11].id">
                              <label>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.label}}</label>
                            </div>
                          </div>
                          <div class="col-2" *ngIf="cellArrayIndex == 0 && (rowIndex != 1 && columnIndex != 1)">
                            <div ngbDropdown>
                              <button type="button" class="control-buttons mt-1 remove-caret text-center" ngbDropdownToggle>
                                <span class="threeDotVerticalIcon"></span>
                              </button>
                              <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan == null && getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                                <button ngbDropdownItem (click)="editCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="editIcon"></span> &nbsp; Edit
                                </button>
                                <button ngbDropdownItem (click)="deleteCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="deleteIcon"></span> &nbsp; Delete
                                </button>
                                <!-- <button ngbDropdownItem (click)="copyCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="copyIcon"></span> &nbsp; Copy cell
                                </button> -->
                                <!-- <button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == 1">
                                  <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                                </button> -->
                                <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1)">
                                  <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                                </button>
                                <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="rowIndex == (getTableDataField.length - 1)">
                                  <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                                </button>
                                <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitRow"></span> &nbsp; Split row
                                </button>
                                <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitColumn"></span> &nbsp; Split column
                                </button>
                              </div>
                              <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan != null || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                <button ngbDropdownItem (click)="editCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="editIcon"></span> &nbsp; Edit
                                </button>
                                <button ngbDropdownItem (click)="deleteCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="deleteIcon"></span> &nbsp; Delete
                                </button>
                                <!-- <button ngbDropdownItem (click)="copyCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="copyIcon"></span> &nbsp; Copy cell
                                </button> -->
                                <!-- <button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == 0 || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                  <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                                </button> -->
                                <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                  <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                                </button>
                                <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="rowIndex == (getTableDataField.length - 1) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                                  <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                                </button>
                                <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitRow"></span> &nbsp; Split row
                                </button>
                                <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitColumn"></span> &nbsp; Split column
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Field Designing -->
                      <br *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked">
                      <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked">
                        <div class="row" *ngIf="cellArrayIndex == 0">
                          <div class="col-6">
                            <h5>Design Row - {{rowIndex+1}} & Column - {{columnIndex+1}}</h5>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div ngbAccordion>
                              <div ngbAccordionItem>
                                <div ngbAccordionHeader>
                                  <button class="accordion-button control-buttons" ngbAccordionToggle>
                                    <div class="col-10 wordWrap mt-1">
                                      <h5>FIELD - {{cellArrayIndex+1}} <span *ngIf="getCellInfoInnerArrayDataValue(rowIndex,columnIndex,cellArrayIndex,'selectedType')?.value != null"><b>({{getCellInfoInnerArrayDataValue(rowIndex,columnIndex,cellArrayIndex,'selectedType')?.value}})</b></span></h5>
                                    </div>
                                  </button>
                                </div>
                                <div ngbAccordionCollapse>
                                  <div ngbAccordionBody>
                                    <ng-template>
                                      <div class="row">
                                        <div [ngClass]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId != null ? 'col-6' : 'col-12'">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" formControlName="dataTypeId" (change)="getSelectedCellType($event,rowIndex,columnIndex,cellArrayIndex)">
                                              <option [ngValue]="null" selected>--Select--</option>
                                              <option *ngFor="let tt of fieldType" [value]="tt.id"><b>{{tt.name}}</b></option>
                                            </select>
                                            <label for="type"><b>Select Type</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="annotatedName" formControlName="annotatedName" name="annotatedName"
                                              placeholder="Enter Annotated Name" max="500" maxlength="500">
                                            <label for="annotatedName"><b>Annotated Name</b></label>
                                            <i><b>Note:</b> Maximium character allowed 500</i>
                                          </div>
                                        </div>
                                        <!-- <div class="col-6" *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="tagName" formControlName="tag" name="tag"
                                              placeholder="Enter Tag Name" max="250" maxlength="250">
                                            <label for="tagName"><b>Tag Name</b></label>
                                            <i><b>Note:</b> Maximium character allowed 250</i>
                                          </div>
                                        </div> -->
                                        <div class="col-6" *ngIf="[11,15].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="label" formControlName="label" name="label"
                                              placeholder="Enter Label" max="255" maxlength="255">
                                            <label for="label"><b>Label</b></label>
                                            <i><b>Note:</b> Maximium character allowed 255</i>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-6" *ngIf="[1,2,3,4,5].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="placeholder" formControlName="placeholder" name="placeholder"
                                              placeholder="Enter Placeholder" max="255" maxlength="255">
                                            <label for="placeholder"><b>Placeholder</b></label>
                                            <i><b>Note:</b> Maximium character allowed 255</i>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="note" formControlName="note" name="note"
                                              placeholder="Enter Note" max="1000" maxlength="1000">
                                            <label for="note"><b>Note</b></label>
                                            <i><b>Note:</b> Maximium character allowed 1000</i>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="defaultVal" formControlName="defaultVal" name="defaultVal"
                                              placeholder="Enter default value" min="0">
                                            <label for="defaultVal"><b>Default Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                                          <div formGroupName="formatting">
                                            <div class="form-floating mb-3">
                                              <select class="form-select" id="dateFormat" formControlName="dateFormat">
                                                <option [ngValue]="''" selected>Select Date Format</option>
                                                <option *ngFor="let item of dateFormat" [value]="item.format"><b>{{item.format}}</b></option>
                                              </select>
                                              <label for="dateFormat"><b>Date Format</b></label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[10].id">
                                          <div formGroupName="formatting">
                                            <label><b>Select Time Format</b></label> &nbsp;
                                            <div class="form-check-inline">
                                              <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                                                id="12hours" value="12 hrs"> &nbsp;
                                              <label class="form-check-label" for="12hours">
                                                12 Hrs
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                                                id="24hours" value="24 hrs">&nbsp;
                                              <label class="form-check-label" for="24hours">
                                                24 Hrs
                                              </label>
                                            </div>
                                            <br>
                                            <label for="timeFormat"><b>Time Format</b></label>&nbsp;
                                            <div class="form-check-inline">
                                              <input type="checkbox" id="hour" value="hour" formControlName="hour"> &nbsp;
                                              <label class="form-check-label">
                                                Hour
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <input type="checkbox" id="minute" value="minute" formControlName="minute">&nbsp;
                                              <label class="form-check-label">
                                                Minute
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <input type="checkbox" id="second" value="second" formControlName="second"> &nbsp;
                                              <label class="form-check-label">
                                                Second
                                              </label>
                                            </div>
                                            <div class="form-check-inline" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hourFormat == '12 hrs'">
                                              <input type="checkbox" id="midDay" value="midDay" formControlName="midDay"> &nbsp;
                                              <label class="form-check-label">
                                                AM/PM
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[2].id">
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="defaultVal" formControlName="defaultVal" name="defaultVal"
                                              placeholder="Enter default value" min="0">
                                            <label for="defaultVal"><b>Default Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,3].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="unit" formControlName="unit">
                                              <option [ngValue]="null" selected>Select Unit</option>
                                              <option *ngFor="let item of unitData" [value]="item.unit"><b>{{item.unit}}</b></option>
                                            </select>
                                            <label for="unit"><b>Units</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="tagName" formControlName="tag" name="tag"
                                              placeholder="Enter Tag Name" max="250" maxlength="250">
                                            <label for="tagName"><b>Tag Name</b></label>
                                            <i><b>Note:</b> Maximium character allowed 250</i>
                                          </div>
                                        </div>
                                        <div class="col-12" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[2].id">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="decimalPoints" formControlName="decimalPoints">
                                              <option [ngValue]="null" selected>--Select--</option>
                                              <option *ngFor="let item of decimalData" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="decimalPoints"><b>Decimal Points</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <h4 *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">Validations</h4>
                                      <hr *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)"/>
                                      <div class="row">
                                        <div class="col-6" *ngIf="[1,2].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <!-- Alphabet or AlphNumeric -->
                                          <div class="form-check-inline" *ngFor="let radioButtonValue of radioButtonData; let i = index">
                                            <input class="form-check-input" type="radio" formControlName="isAlphabet" id="radioButtonValue.name"
                                              [value]="radioButtonValue.value">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                              &nbsp;{{radioButtonValue.name}}
                                            </label>
                                          </div>
                                          <button class="control-buttons" (click)="resetData('isAlphabet','tableComponent',null,rowIndex,columnIndex,cellArrayIndex)">
                                            <i class="resetIcon" aria-hidden="true"></i>
                                          </button>
                                        </div>
                                        <div class="col-6" *ngIf="[3,12].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <!-- Is Formula -->
                                          <div class="form-check-inline">
                                            <input type="checkbox" class="form-check-input" id="isFormula" formControlName="isFormula" name="isFormula">
                                            <label for="isFormula">&nbsp;Is Formula Applicable ?</label>
                                          </div>
                                          <!-- Required -->
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="required" name="required" id="required" value="required">
                                            <label class="form-check-label" for="required">
                                              &nbsp;Required
                                            </label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,4,5,6,7,8,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <!-- Required -->
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="required" name="required" id="required" value="required">
                                            <label class="form-check-label" for="required">
                                              &nbsp;Required
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <br *ngIf="[1,2,3,4,5,12].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                      <br *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                      <div class="row">
                                        <div class="col-6" *ngIf="[1,2,3,4,5].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <!-- Minimium Value -->
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="minValue" formControlName="minValue" name="minValue"
                                              placeholder="Enter Minimium Value" min="0">
                                            <label for="minValue"><b>Minimium Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,3,4,5].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <!-- Maximium Value -->
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="maxValue" formControlName="maxValue" name="maxValue"
                                              placeholder="Enter Maximium Length" min="0">
                                            <label for="maxValue"><b>Maximium Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-5" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                                          <!-- Min Date -->
                                          <div class="form-group mb-3">
                                            <div class="input-group">
                                              <input class="form-control" placeholder="Minimium Date" formControlName="minDate" 
                                              name="minDate" ngbDatepicker #colMin="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="colMin.toggle()">
                                              <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" (click)="colMin.toggle()" type="button">
                                                  <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </button>
                                                <button class="control-buttons mt-1" (click)="resetDate(true,'tableComponent',null,rowIndex,columnIndex,cellArrayIndex)" type="button">
                                                  <i class="resetIcon" aria-hidden="true"></i>
                                                </button>
                                              </div>
                                            </div>
                                            <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                              let-disabled="disabled" let-focused="focused">
                                              <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                                [class.text-muted]="date.month !== currentMonth">
                                                {{ date.day }}
                                              </span>
                                            </ng-template>
                                          </div>
                                        </div>
                                        <!-- <div class="col-2 mt-1" style="text-align-last: center;" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                                          <button class="control-buttons" (click)="resetDate('tableComponent',null,rowIndex,columnIndex,cellArrayIndex)" type="button">
                                            <i class="resetIcon" aria-hidden="true"></i>
                                          </button>
                                        </div> -->
                                        <div class="col-5" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                                          <!-- Max Date -->
                                          <div class="form-group mb-3">
                                            <div class="input-group">
                                              <input class="form-control" placeholder="Maximium Date" formControlName="maxDate"
                                              name="maxDate" ngbDatepicker #colMax="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="colMax.toggle()">
                                              <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" (click)="colMax.toggle()" type="button">
                                                  <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </button>
                                                <button class="control-buttons mt-1" (click)="resetDate(false,'tableComponent',null,rowIndex,columnIndex,cellArrayIndex)" type="button">
                                                  <i class="resetIcon" aria-hidden="true"></i>
                                                </button>
                                              </div>
                                            </div>
                                            <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                              let-disabled="disabled" let-focused="focused">
                                              <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                                [class.text-muted]="date.month !== currentMonth">
                                                {{ date.day }}
                                              </span>
                                            </ng-template>
                                          </div>
                                        </div>
                                      </div>
                                      <h4 *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">Options</h4>
                                      <hr *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)"/>
                                      <div class="row" formArrayName='options' *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                        <div cdkDropList (cdkDropListDropped)="dropTableComponentRadio($event,rowIndex,columnIndex,cellArrayIndex)">
                                          <div *ngFor="let opt of getOptionTableDataField(rowIndex,columnIndex,cellArrayIndex).controls; let cdkDropIndex = index;">
                                            <div class="form-group row" [formGroupName]="cdkDropIndex" cdkDrag cdkDragLockAxis="y" [cdkDragPreviewContainer]="'parent'" 
                                            [cdkDragPreviewClass]="'add-padding-to-dragged'">
                                              <!--  drag button -->
                                              <div class="col-sm-1 text-center mt-3">
                                                <button class="control-buttons cursorData" cdkDragHandle>
                                                  <span class="dragBarIcon"></span>
                                                </button>
                                              </div>
                                              <!-- Name -->
                                              <div class="col">
                                                <div class="form-floating mb-3">
                                                  <input type="text" class="form-control" formControlName="name" placeholder="Enter option name" autocomplete="off"
                                                  max="255" maxlength="255">
                                                  <label for="name"><b>Name</b></label>
                                                </div>
                                              </div>
                                              <!-- Value -->
                                              <div class="col">
                                                <div class="form-floating mb-3">
                                                  <input type="text" class="form-control" formControlName="value" placeholder="Enter option value" autocomplete="off"
                                                  max="255" maxlength="255">
                                                  <label for="value"><b>Value</b></label>
                                                </div>
                                              </div>
                                              <!-- Code -->
                                              <div class="col">
                                                <div class="form-floating mb-3">
                                                  <input type="number" min="0" class="form-control" formControlName="code" autocomplete="off"
                                                    placeholder="Enter option Code">
                                                  <label for="code"><b>Code</b></label>
                                                </div>
                                              </div>
                                              <!-- close -->
                                              <div class="col-sm-1 text-center mt-3">
                                                <button class="btn-close" (click)="removeOptionInTableDataField(rowIndex,columnIndex,cellArrayIndex,cdkDropIndex)"></button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row" *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                        <div class="col-12">
                                          <button class=" btn btn-outline-primary  btn-block" (click)="addOptionInTableDataField(rowIndex,columnIndex,cellArrayIndex);">
                                            <b>
                                              AddOption
                                            </b>
                                          </button>
                                        </div>
                                      </div>
                                      <h4 *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">Formatting</h4>
                                      <hr *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id"/>
                                      <div class="row">
                                        <div class="col-6 mt-2" formGroupName="formatting" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                                          <!-- Bold -->
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="bold" name="bold" id="bold" value="bold"
                                              (change)="onTableBoldCheckboxChange($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                            <label class="form-check-label" for="bold">
                                              Bold
                                            </label>
                                          </div>
                                          <!-- Italic -->
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="italic" name="italic" id="italic" value="italic"
                                              (change)="onTableItalicCheckboxChange($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                            <label class="form-check-label" for="italic">
                                              Italic
                                            </label>
                                          </div>
                                          <!-- Underline -->
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="underline" name="underline" id="underline" value="underline"
                                              (change)="onTableUnderlineCheckboxChange($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                            <label class="form-check-label" for="underline">
                                              Underline
                                            </label>
                                          </div>
                                        </div>
                                        <div class="col-6" formGroupName="formatting" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                                          <!-- Alignment -->
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="alignment" formControlName="alignment">
                                              <option *ngFor="let item of alignment" [value]="item"><b>{{item}}</b></option>
                                            </select>
                                            <label for="alignment"><b>Select Alignment</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-6" formGroupName="formatting" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                                          <!-- Color -->
                                          <div class="form-floating mb-3">
                                            <input type="color" class="form-control" formControlName="color" id="color" name="color"
                                              placeholder="Enter Label">
                                            <label for="color"><b>Select Color</b></label>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isButtonClicked">
                    <div *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isButtonClicked">
                      <div class="row">
                        <div class="col-12 text-end">
                          <button class="btn btn-outline-primary" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.dataTypeId != null" (click)="saveCell(rowIndex,columnIndex)"><b>Add</b></button> &nbsp;
                          <button class="btn btn-outline-danger" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isEdited" (click)="deGenerateCellForm()"><b>Close</b></button>
                          <button class="btn btn-outline-danger" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isEdited" (click)="cancelCell(rowIndex,columnIndex)"><b>Cancel</b></button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Dynamic Table Component-->
      <!-- <div *ngIf="componentsElement.label && componentsElement.cellInfo">
        <div class="row">
          <div class="col">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" formControlName="label" id="label" name="label"
                placeholder="Enter Table Heading" required max="255" maxlength="255">
              <label for="label"><b>Table Heading</b></label>
              <i><b>Note:</b> Maximium character allowed 255</i>
            </div>
          </div>
        </div>
        <div class="row" formGroupName="formatting" *ngIf="componentsElement.formatting && !componentsElement.columnData && componentsElement.cellInfo">
          <div class="col">
            <div class="form-floating mb-3">
              <input type="number" class="form-control" formControlName="row" id="row" name="row"
                placeholder="Enter No. of Rows" required min="0" [readOnly]="popupForm.get('isRowColumnDataAdd')?.value">
              <label for="label"><b>Rows</b></label>
            </div>
          </div>
          <div class="col">
            <div class="form-floating mb-3">
              <input type="number" class="form-control" formControlName="column" id="column" name="column"
                placeholder="Enter No. of Columns" required min="0" [readOnly]="popupForm.get('isRowColumnDataAdd')?.value">
              <label for="label"><b>Columns</b></label>
            </div>
          </div>
          <div class="col-2 mt-2">
            <button class="control-buttons" title="Generate" (click)="generateTable()" [disabled]="popupForm.get('isRowColumnDataAdd')?.value">
              <span class="plusIcon"></span>
            </button> &nbsp;
            <button class="control-buttons" title="Reset" (click)="resetTable()">
              <span class="resetIcon"></span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col" style="overflow-y:auto">
            <table class="table table-bordered" formArrayName="cellInfo">
              <tbody cdkDropList (cdkDropListDropped)="dropRow($event)">
                <tr cdkDrag cdkDragLockAxis="y" [cdkDragPreviewContainer]="'parent'" 
                  [cdkDragPreviewClass]="'add-padding-to-dragged'" 
                  *ngFor="let row of getTableDataField.controls;let rowIndex = index" 
                  [formArrayName]="rowIndex">
                  <td [attr.colspan]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.colspan" 
                    [attr.rowspan]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.rowspan"
                    *ngFor="let column of getCellInfoData(rowIndex).controls;let columnIndex = index" 
                    [hidden]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isHidden"
                    [formArrayName]="columnIndex">
                    <div *ngFor="let cellArray of getCellInfoInnerArray(rowIndex,columnIndex).controls;let cellArrayIndex = index" 
                      [formGroupName]="cellArrayIndex">
                      Adding Fields
                      <div class="container" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved == undefined && rowIndex === 0 && columnIndex === 0">
                        Comment Code : <p class="text-center mt-1">#</p>
                      </div>
                      <div class="container" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved == undefined && (rowIndex !== 0 || columnIndex !== 0)">
                        <div class="row">
                          <div class="col text-center mt-1">
                            <button class="control-buttons cursorData" *ngIf="columnIndex == 0"
                              title="Drag Row" cdkDragHandle>
                              <span class="dragBarIcon"></span>
                            </button>&nbsp;
                            <button class="control-buttons" *ngIf="columnIndex == 0" 
                              title="Add rows to bottom"
                              (click)="addRowColumn(rowIndex,columnIndex,'row')">
                              <span class="plusIcon"></span>
                            </button>
                            <button class="control-buttons" *ngIf="rowIndex == 0" 
                              title="Add columns to right"
                              (click)="addRowColumn(rowIndex,columnIndex,'column')">
                              <span class="plusIcon"></span>
                            </button>&nbsp;
                            <button class="control-buttons" *ngIf="columnIndex == 0"
                              title="Copy rows to bottom"
                              (click)="copyRowColumn(rowIndex,columnIndex,'row')"
                              [hidden]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isRowSpan || 
                              getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isColSpan">
                              <span class="copyCellIcon"></span>
                            </button>
                            <button class="control-buttons" *ngIf="rowIndex == 0"
                              title="Copy columns to right"
                              (click)="copyRowColumn(rowIndex,columnIndex,'column')"
                              [hidden]="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isColSpan || 
                              getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isRowSpan">
                              <span class="copyCellIcon"></span>
                            </button>&nbsp;
                            <button class="control-buttons" *ngIf="columnIndex == 0"
                              title="Delete row"
                              (click)="deleteRowColumn(rowIndex,columnIndex,'row')">
                              <span class="deleteIcon"></span>
                            </button>
                            <button class="control-buttons" *ngIf="rowIndex == 0"
                              title="Delete column"
                              (click)="deleteRowColumn(rowIndex,columnIndex,'column')">
                              <span class="deleteIcon"></span>
                            </button>
                            Commented Code<button class="control-buttons cursorData" *ngIf="rowIndex == 0"
                              title="Drag Column" cdkDragHandle>
                              <span class="dragIcon"></span>
                            </button> upto here
                          </div>
                        </div>
                      </div>
                      Three Dot Options
                      <div class="container" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved != undefined && !getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved">
                        <div class="row">
                          <div class="col-10">
                            <button style="width: 100%;" class="btn btn-outline-secondary" 
                              *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked" 
                              (click)="generateCellForm(rowIndex,columnIndex,cellArrayIndex)">
                              <span class="plusIcon"></span>
                            </button>
                          </div>
                          <div class="col-2" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked">
                            <div ngbDropdown>
                              <button type="button" class="control-buttons mt-1 remove-caret text-center" ngbDropdownToggle>
                                <span class="threeDotVerticalIcon"></span>
                              </button>
                              <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan == null && getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                                Comment Code<button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == 1">
                                  <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                                </button> upto here
                                <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1)">
                                  <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                                </button>
                                <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="rowIndex == (getTableDataField.length - 1)">
                                  <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                                </button>
                                <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitRow"></span> &nbsp; Split row
                                </button>
                                <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitColumn"></span> &nbsp; Split column
                                </button>
                              </div>
                              <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan != null || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                Commented Code<button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == 1 || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                  <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                                </button> upto here
                                <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                  <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                                </button>
                                <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="rowIndex == (getTableDataField.length - 2) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                                  <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                                </button>
                                <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitRow"></span> &nbsp; Split row
                                </button>
                                <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitColumn"></span> &nbsp; Split column
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      Output Showing Data
                      <div class="container" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isSaved">
                        <div class="row">
                          <div class="col-10">
                            TextView
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[0].id">
                              <input class="form-control" type="text" 
                              [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''"
                              [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0"
                              [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 999999999">
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <label>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</label>
                              </div>
                            </div>
                            TextArea
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[1].id">
                              <textarea class="form-control" [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                              [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" autocomplete="off"
                              [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                              [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                              [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0"
                              [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 999999999"></textarea>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined" class="row ">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            Number
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[2].id">
                              <div class="col">
                                <input type="number" class="form-control" [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                                [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" autocomplete="off"
                                [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                                [disabled]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isFormula != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isFormula : false" 
                                [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                                [step]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.decimalPoints != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.decimalPoints : 0" 
                                [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999" 
                                [min]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                [max]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999">
                                <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                  <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                                </div>
                              </div>
                              <div class="col-sm-2 mt-2" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.unit != undefined">
                                <span><b>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.unit}}</b></span>
                              </div>
                            </div>
                            Email
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[3].id">
                              <div class="col">
                                <input type="email" class="form-control" 
                                [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                                [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName"
                                [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                                [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                                [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999" 
                                [min]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                [max]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999">
                                <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                  <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                                </div>
                              </div>
                            </div>
                            Password
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[4].id">
                              <div class="col">
                                <input type="password" class="form-control" 
                                [id]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName" 
                                [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName"
                                [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.placeholder : ''" 
                                [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false" 
                                [minLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                [maxLength]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999" 
                                [min]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.minValue : 0" 
                                [max]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.maxValue : 9999999999">
                                <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                  <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                                </div>
                              </div>
                            </div>
                            Checkbox
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[5].id">
                              <div class="form-check" *ngFor="let option of getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.options;let check = index">
                                <input id="option.name" class="form-check-input" type="checkbox" [name]="'check-'+check"
                                [value]="option.value" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                <label for="option.name" class="form-check-label">{{option.name}}</label>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            Radiobutton
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[6].id">
                              <div class="form-check" *ngFor="let option of getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.options;let radio = index">
                                <input id="option.name" class="form-check-input" type="radio" [name]="'radio-'+radio"
                                [value]="option.value" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                <label for="option.name" class="form-check-label">{{option.name}}</label>
                              </div>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            Selectionbox
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[7].id">
                              <select class="form-select" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                <option [ngValue]="null" selected>Select</option>
                                <option *ngFor="let option of getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.options" [value]="option.name">
                                  {{option.name}}
                                </option>
                              </select>
                              <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">
                                <i>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              </div>
                            </div>
                            Sub-Heading
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                              <label class="col-form-label col-sm-12 " 
                              [style.text-decoration]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.underline != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.underline : ''"
                              [style.font-style]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.italic != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.italic : ''" 
                              [style.font-weight]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.bold != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.bold : ''"
                              [style.color]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.color != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.color : ''" 
                              [style.text-align]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.alignment != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.alignment : ''">
                              {{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.label}}</label>
                            </div>
                            Date
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                              <div class="form-group" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isUnknownDate">
                                <div class="input-group">
                                  <input class="form-control" 
                                  [placeholder]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.dateFormat != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.dateFormat : ''" 
                                  [name]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.annotatedName"
                                  ngbDatepicker #d="ngbDatepicker" 
                                  [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false"
                                  [readOnly]="true" [dayTemplate]="customDay">
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                                <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                  let-disabled="disabled" let-focused="focused">
                                  <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                    [class.text-muted]="date.month !== currentMonth">
                                    {{ date.day }}
                                  </span>
                                </ng-template>
                              </div>
                              <div class="form-group" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isUnknownDate">
                                <div class="input-group">
                                  <div class="form-floating mb-3">
                                    <select class="form-select" id="date" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                      <option selected value="UK">UK</option>
                                      <option *ngFor="let item of dateData" [value]="item"><b>{{item}}</b></option>
                                    </select>
                                    <label for="date"><b>Date </b></label>
                                  </div>&nbsp;&nbsp;
                                  <div class="form-floating mb-3">
                                    <select class="form-select" id="month" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                      <option value="UNK" selected>UNK</option>
                                      <option *ngFor="let item of monthData" [value]="item"><b>{{item}}</b></option>
                                    </select>
                                    <label for="month"><b>Month </b></label>
                                  </div>&nbsp;&nbsp;
                                  <div class="form-floating mb-3">
                                    <select class="form-select" id="year" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                      <option value="UNKK" selected>UNKK</option>
                                      <option *ngFor="let item of yearData" [value]="item"><b>{{item}}</b></option>
                                    </select>
                                    <label for="year"><b>Year </b></label>
                                  </div>&nbsp;&nbsp;
                                </div>
                              </div>
                              <i *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                              <br>
                              <div class="row">
                                <div class="form-floating mb-3">
                                  <div class="form-check-inline">
                                    <input class="form-check-input" type="checkbox" formControlName="isUnknownDate" name="isUnknownDate" id="isUnknownDate"
                                    value="isUnknownDate" (change)="checkDateIsUnkown($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                    <label for="isUnknownDate">
                                      <b>Unknown Date?</b>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            Time
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[10].id">
                              <div class="form-group" [ngSwitch]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hourFormat">
                                12 Hrs
                                <div class="row" *ngSwitchCase="'12 hrs'">
                                  hours
                                  <div class="col-3" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == true || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == 'true'">
                                    <div class="form-floating">
                                      <select class="form-select" id="hours" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected disabled>Select</option>
                                        <option value="UK" selected>UK</option>
                                        <option *ngFor="let hr of hours12" [value]="hr">{{hr}}</option>
                                      </select>
                                      <label for="hours">Hrs </label>
                                    </div>
                                  </div>
                                  minutes
                                  <div class="col-3" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == true">
                                    <div class="form-floating">
                                      <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected disabled>Select</option>
                                        <option value="UM" selected>UM</option>
                                        <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                                      </select>
                                      <label for="minutes">Min </label>
                                    </div>
                                  </div>
                                  Seconds
                                  <div class="col-3" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == true">
                                    <div class="form-floating">
                                      <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected disabled>Select</option>
                                        <option value="UN" selected>UN</option>
                                        <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                                      </select>
                                      <label for="minutes">Sec </label>
                                    </div>
                                  </div>
                                  midday
                                  <div class="col-3" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hourFormat == '12 hrs' && getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.midDay == true || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.midDay == 'true'">
                                    <div class="form-floating">
                                      <select class="form-select" id="midday" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected disabled>Select</option>
                                        <option value="UM" selected>UM</option>
                                        <option *ngFor="let md of midday" [value]="md">{{md}}</option>
                                      </select>
                                      <label for="midday">AM/PM </label>
                                    </div>
                                  </div>
                                </div>
                                24 Hrs
                                <div class="row" *ngSwitchCase="'24 hrs'">
                                  hours
                                  <div class="col-4" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.hour == true">
                                    <div class="form-floating">
                                      <select class="form-select" id="hours" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected disabled>Select</option>
                                        <option value="UK" selected>UK</option>
                                        <option *ngFor="let hr of hours24" [value]="hr">{{hr}}</option>
                                      </select>
                                      <label for="hours">Hrs</label>
                                    </div>
                                  </div>
                                  minutes
                                  <div class="col-4" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == true || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.minute == 'true'">
                                    <div class="form-floating">
                                      <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected disabled>Select</option>
                                        <option value="UM" selected>UM</option>
                                        <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                                      </select>
                                      <label for="minutes">Min </label>
                                    </div>
                                  </div>
                                  Second
                                  <div class="col-4" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == 'true' || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.formatting.second == true">
                                    <div class="form-floating">
                                      <select class="form-select" id="minutes" [required]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required != undefined ? getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.required : false">
                                        <option selected disabled>Select</option>
                                        <option value="UN" selected>UN</option>
                                        <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                                      </select>
                                      <label for="minutes">Sec </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <i *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note != undefined">{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.note}}</i>
                            </div>
                            Label
                            <div class="row" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[11].id">
                              <label>{{getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.label}}</label>
                            </div>
                          </div>
                          <div class="col-2" *ngIf="cellArrayIndex == 0">
                            <div ngbDropdown>
                              <button type="button" class="control-buttons mt-1 remove-caret text-center" ngbDropdownToggle>
                                <span class="threeDotVerticalIcon"></span>
                              </button>
                              <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan == null && getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                                <button ngbDropdownItem (click)="editCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="editIcon"></span> &nbsp; Edit
                                </button>
                                <button ngbDropdownItem (click)="deleteCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="deleteIcon"></span> &nbsp; Delete
                                </button>
                                Commented Code<button ngbDropdownItem (click)="copyCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="copyIcon"></span> &nbsp; Copy cell
                                </button> 
                                <button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == 1">
                                  <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                                </button> upto here
                                <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1)">
                                  <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                                </button>
                                <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="rowIndex == (getTableDataField.length - 1)">
                                  <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                                </button>
                                <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitRow"></span> &nbsp; Split row
                                </button>
                                <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitColumn"></span> &nbsp; Split column
                                </button>
                              </div>
                              <div ngbDropdownMenu *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.colspan != null || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                <button ngbDropdownItem (click)="editCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="editIcon"></span> &nbsp; Edit
                                </button>
                                <button ngbDropdownItem (click)="deleteCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="deleteIcon"></span> &nbsp; Delete
                                </button>
                                Commented Code <button ngbDropdownItem (click)="copyCell(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="copyIcon"></span> &nbsp; Copy cell
                                </button>
                                <button ngbDropdownItem (click)="mergeFromLeftCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == 0 || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                  <span class="mergeFromLeft"></span> &nbsp; Merge cell from left
                                </button> upto here
                                <button ngbDropdownItem (click)="mergeFromRightCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="columnIndex == (getCellInfoData(rowIndex).length - 1) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan != null">
                                  <span class="mergeFromRight"></span> &nbsp; Merge cell from right
                                </button>
                                <button ngbDropdownItem (click)="mergeFromBottomCell(rowIndex,columnIndex,cellArrayIndex)"
                                  [hidden]="rowIndex == (getTableDataField.length - 1) || getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.rowspan == null">
                                  <span class="mergeFromBottom"></span> &nbsp; Merge cell from bottom
                                </button>
                                <button ngbDropdownItem (click)="splitRow(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitRow"></span> &nbsp; Split row
                                </button>
                                <button ngbDropdownItem (click)="splitColumn(rowIndex,columnIndex,cellArrayIndex)">
                                  <span class="splitColumn"></span> &nbsp; Split column
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      Field Designing
                      <br *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked">
                      <div class="container" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.isButtonClicked">
                        <div class="row" *ngIf="cellArrayIndex == 0">
                          <div class="col-6">
                            <h5>Design Row - {{rowIndex+1}} & Column - {{columnIndex+1}}</h5>
                          </div>
                          <div class="col-6 text-end">
                            <button class="control-buttons" style="color: blue;" 
                              (click)="addDataInCell(rowIndex,columnIndex)">
                              <u>
                                Add Field
                              </u>
                            </button>&nbsp;
                            <button class="control-buttons" style="color: blue;" 
                              (click)="removeDataFromCell(rowIndex,columnIndex)">
                              <u>
                                Remove Fields
                              </u>
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div ngbAccordion>
                              <div ngbAccordionItem>
                                <div ngbAccordionHeader>
                                  <button class="accordion-button control-buttons" ngbAccordionToggle>
                                    <div class="col-10 wordWrap mt-1">
                                      <h5>FIELD - {{cellArrayIndex+1}} <span *ngIf="getCellInfoInnerArrayDataValue(rowIndex,columnIndex,cellArrayIndex,'selectedType')?.value != null"><b>({{getCellInfoInnerArrayDataValue(rowIndex,columnIndex,cellArrayIndex,'selectedType')?.value}})</b></span></h5>
                                    </div>
                                    <div class="col text-end">
                                      <button class="control-buttons" title="Remove Rule" (click)="removeDataInCell(rowIndex,columnIndex,cellArrayIndex)">
                                        <span class="deleteIcon"></span>
                                      </button>
                                    </div>
                                  </button>
                                </div>
                                <div ngbAccordionCollapse>
                                  <div ngbAccordionBody>
                                    <ng-template>
                                      <div class="row">
                                        <div [ngClass]="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId != null ? 'col-6' : 'col-12'">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" formControlName="dataTypeId" (change)="getSelectedCellType($event,rowIndex,columnIndex,cellArrayIndex)">
                                              <option [ngValue]="null" selected>--Select--</option>
                                              <option *ngFor="let tt of tableType" [value]="tt.id"><b>{{tt.name}}</b></option>
                                            </select>
                                            <label for="type"><b>Select Type</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="annotatedName" formControlName="annotatedName" name="annotatedName"
                                              placeholder="Enter Annotated Name" max="500" maxlength="500">
                                            <label for="annotatedName"><b>Annotated Name</b></label>
                                            <i><b>Note:</b> Maximium character allowed 500</i>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[11,15].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="label" formControlName="label" name="label"
                                              placeholder="Enter Label" max="255" maxlength="255">
                                            <label for="label"><b>Label</b></label>
                                            <i><b>Note:</b> Maximium character allowed 255</i>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-6" *ngIf="[1,2,3,4,5].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="placeholder" formControlName="placeholder" name="placeholder"
                                              placeholder="Enter Placeholder" max="255" maxlength="255">
                                            <label for="placeholder"><b>Placeholder</b></label>
                                            <i><b>Note:</b> Maximium character allowed 255</i>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="note" formControlName="note" name="note"
                                              placeholder="Enter Note" max="255" maxlength="255">
                                            <label for="note"><b>Note</b></label>
                                            <i><b>Note:</b> Maximium character allowed 255</i>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="defaultVal" formControlName="defaultVal" name="defaultVal"
                                              placeholder="Enter default value" min="0">
                                            <label for="defaultVal"><b>Default Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                                          <div formGroupName="formatting">
                                            <div class="form-floating mb-3">
                                              <select class="form-select" id="dateFormat" formControlName="dateFormat">
                                                <option selected disabled>Select Date Format</option>
                                                <option *ngFor="let item of dateFormat" [value]="item.format"><b>{{item.format}}</b></option>
                                              </select>
                                              <label for="dateFormat"><b>Date Format</b></label>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[10].id">
                                          <div formGroupName="formatting">
                                            <label><b>Select Time Format</b></label> &nbsp;
                                            <div class="form-check-inline">
                                              <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                                                id="12hours" value="12 hrs"> &nbsp;
                                              <label class="form-check-label" for="12hours">
                                                12 Hrs
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <input class="form-check-input" type="radio" name="hourFormat" formControlName="hourFormat"
                                                id="24hours" value="24 hrs">&nbsp;
                                              <label class="form-check-label" for="24hours">
                                                24 Hrs
                                              </label>
                                            </div>
                                            <br>
                                            <label for="timeFormat"><b>Time Format</b></label>&nbsp;
                                            <div class="form-check-inline">
                                              <input type="checkbox" id="hour" value="hour" formControlName="hour"> &nbsp;
                                              <label class="form-check-label">
                                                Hour
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <input type="checkbox" id="minute" value="minute" formControlName="minute">&nbsp;
                                              <label class="form-check-label">
                                                Minute
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <input type="checkbox" id="second" value="second" formControlName="second"> &nbsp;
                                              <label class="form-check-label">
                                                Second
                                              </label>
                                            </div>
                                            <div class="form-check-inline">
                                              <input type="checkbox" id="midDay" value="midDay" formControlName="midDay"> &nbsp;
                                              <label class="form-check-label">
                                                AM/PM
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[2].id">
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="defaultVal" formControlName="defaultVal" name="defaultVal"
                                              placeholder="Enter default value" min="0">
                                            <label for="defaultVal"><b>Default Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,3].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          TEST
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="unit" formControlName="unit">
                                              <option [ngValue]="null" selected disabled>Select Unit</option>
                                              <option *ngFor="let item of unitData" [value]="item.unit"><b>{{item.unit}}</b></option>
                                            </select>
                                            <label for="unit"><b>Units</b></label>
                                          </div>
                                        </div>
                                        <div class="col-12" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[2].id">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="decimalPoints" formControlName="decimalPoints">
                                              <option [ngValue]="null" selected>--Select--</option>
                                              <option *ngFor="let item of decimalData" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="decimalPoints"><b>Decimal Points</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <h4 *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">Validations</h4>
                                      <hr *ngIf="[1,2,3,4,5,6,7,8,12,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)"/>
                                      <div class="row">
                                        <div class="col-6" *ngIf="[1,2].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          Alphabet or AlphNumeric
                                          <div class="form-check-inline" *ngFor="let radioButtonValue of radioButtonData; let i = index">
                                            <input class="form-check-input" type="radio" formControlName="isAlphabet" id="radioButtonValue.name"
                                              [value]="radioButtonValue.value">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                              &nbsp;{{radioButtonValue.name}}
                                            </label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[3,12].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          Is Formula
                                          <div class="form-check-inline">
                                            <input type="checkbox" class="form-check-input" id="isFormula" formControlName="isFormula" name="isFormula">
                                            <label for="isFormula">&nbsp;Is Formula Applicable ?</label>
                                          </div>
                                          Required
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="required" name="required" id="required" value="required">
                                            <label class="form-check-label" for="required">
                                              &nbsp;Required
                                            </label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,4,5,6,7,8,13].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          Required
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="required" name="required" id="required" value="required">
                                            <label class="form-check-label" for="required">
                                              &nbsp;Required
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <br *ngIf="[1,2,3,4,5,12].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                      <br *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                      <div class="row">
                                        <div class="col-6" *ngIf="[1,2,3,4,5].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          Minimium Value
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="minValue" formControlName="minValue" name="minValue"
                                              placeholder="Enter Minimium Value">
                                            <label for="minValue"><b>Minimium Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="[1,2,3,4,5].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                          Maximium Value
                                          <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="maxValue" formControlName="maxValue" name="maxValue"
                                              placeholder="Enter Maximium Length">
                                            <label for="maxValue"><b>Maximium Value</b></label>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                                          Min Date
                                          <div class="form-group mb-3">
                                            <div class="input-group">
                                              <input class="form-control" placeholder="Minimium Date" formControlName="minDate" 
                                              name="minDate" ngbDatepicker #colMin="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay">
                                              <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" (click)="colMin.toggle()" type="button">
                                                  <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </button>
                                              </div>
                                            </div>
                                            <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                              let-disabled="disabled" let-focused="focused">
                                              <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                                [class.text-muted]="date.month !== currentMonth">
                                                {{ date.day }}
                                              </span>
                                            </ng-template>
                                          </div>
                                        </div>
                                        <div class="col-6" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[9].id">
                                          Max Date
                                          <div class="form-group mb-3">
                                            <div class="input-group">
                                              <input class="form-control" placeholder="Maximium Date" formControlName="maxDate"
                                              name="maxDate" ngbDatepicker #colMax="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay">
                                              <div class="input-group-append">
                                                <button class="btn btn-outline-secondary" (click)="colMax.toggle()" type="button">
                                                  <i class="fa fa-calendar" aria-hidden="true"></i>
                                                </button>
                                              </div>
                                            </div>
                                            <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                              let-disabled="disabled" let-focused="focused">
                                              <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                                [class.text-muted]="date.month !== currentMonth">
                                                {{ date.day }}
                                              </span>
                                            </ng-template>
                                          </div>
                                        </div>
                                      </div>
                                      <h4 *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">Options</h4>
                                      <hr *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)"/>
                                      <div class="row" formArrayName='options' *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                        <div cdkDropList (cdkDropListDropped)="dropTableComponentRadio($event,rowIndex,columnIndex,cellArrayIndex)">
                                          <div *ngFor="let opt of getOptionTableDataField(rowIndex,columnIndex,cellArrayIndex).controls; let cdkDropIndex = index;">
                                            <div class="form-group row" [formGroupName]="cdkDropIndex" cdkDrag cdkDragLockAxis="y" [cdkDragPreviewContainer]="'parent'" 
                                            [cdkDragPreviewClass]="'add-padding-to-dragged'">
                                               drag button
                                              <div class="col-sm-1 text-center mt-3">
                                                <button class="control-buttons cursorData" cdkDragHandle>
                                                  <span class="dragBarIcon"></span>
                                                </button>
                                              </div>
                                              Name
                                              <div class="col">
                                                <div class="form-floating mb-3">
                                                  <input type="text" class="form-control" formControlName="name" placeholder="Enter option name" autocomplete="off"
                                                  max="255" maxlength="255">
                                                  <label for="name"><b>Name</b></label>
                                                </div>
                                              </div>
                                              Value
                                              <div class="col">
                                                <div class="form-floating mb-3">
                                                  <input type="text" class="form-control" formControlName="value" placeholder="Enter option value" autocomplete="off"
                                                  max="255" maxlength="255">
                                                  <label for="value"><b>Value</b></label>
                                                </div>
                                              </div>
                                              Code
                                              <div class="col">
                                                <div class="form-floating mb-3">
                                                  <input type="number" min="0" class="form-control" formControlName="code" autocomplete="off"
                                                    placeholder="Enter option Code">
                                                  <label for="code"><b>Code</b></label>
                                                </div>
                                              </div>
                                              close
                                              <div class="col-sm-1 text-center mt-3">
                                                <button class="btn-close" (click)="removeOptionInTableDataField(rowIndex,columnIndex,cellArrayIndex,cdkDropIndex)"></button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row" *ngIf="[6,7,8].includes(getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId)">
                                        <div class="col-12">
                                          <button class=" btn btn-outline-primary  btn-block" (click)="addOptionInTableDataField(rowIndex,columnIndex,cellArrayIndex);">
                                            <b>
                                              AddOption
                                            </b>
                                          </button>
                                        </div>
                                      </div>
                                      <h4 *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">Formatting</h4>
                                      <hr *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id"/>
                                      <div class="row">
                                        <div class="col-6 mt-2" formGroupName="formatting" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                                          Bold
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="bold" name="bold" id="bold" value="bold"
                                              (change)="onTableBoldCheckboxChange($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                            <label class="form-check-label" for="bold">
                                              Bold
                                            </label>
                                          </div>
                                          Italic
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="italic" name="italic" id="italic" value="italic"
                                              (change)="onTableItalicCheckboxChange($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                            <label class="form-check-label" for="italic">
                                              Italic
                                            </label>
                                          </div>
                                          Underline
                                          <div class="form-check-inline">
                                            <input class="form-check-input" type="checkbox" formControlName="underline" name="underline" id="underline" value="underline"
                                              (change)="onTableUnderlineCheckboxChange($event,rowIndex,columnIndex,cellArrayIndex)">&nbsp;
                                            <label class="form-check-label" for="underline">
                                              Underline
                                            </label>
                                          </div>
                                        </div>
                                        <div class="col-6" formGroupName="formatting" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                                          Alignment
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="alignment" formControlName="alignment">
                                              <option *ngFor="let item of alignment" [value]="item"><b>{{item}}</b></option>
                                            </select>
                                            <label for="alignment"><b>Select Alignment</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-6" formGroupName="formatting" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).value.dataTypeId == tableType[8].id">
                                          Color
                                          <div class="form-floating mb-3">
                                            <input type="color" class="form-control" formControlName="color" id="color" name="color"
                                              placeholder="Enter Label">
                                            <label for="color"><b>Select Color</b></label>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isButtonClicked">
                    <div class="container" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isButtonClicked">
                      <div class="row">
                        <div class="col-12 text-end">
                          <button class="btn btn-outline-primary" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.dataTypeId != null" (click)="saveCell(rowIndex,columnIndex)"><b>Add</b></button> &nbsp;
                          <button class="btn btn-outline-danger" *ngIf="!getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isEdited" (click)="deGenerateCellForm()"><b>Close</b></button>
                          <button class="btn btn-outline-danger" *ngIf="getCellInfoInnerArrayData(rowIndex,columnIndex,0).value.isEdited" (click)="cancelCell(rowIndex,columnIndex)"><b>Cancel</b></button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
    </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closePopup()" [disabled]="loading">
      <b>Cancel</b>
    </button>
    <button type="submit" class="btn btn-outline-success" [hidden]="roles == readOnly" *ngIf="!isEdit" (click)="pushFormData(popupForm.value)" [disabled]="loading">
      <b>
        Save <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
      </b>
    </button>
    <button class="btn btn-outline-primary" [hidden]="roles == readOnly" *ngIf="isEdit" (click)="updateData()" [disabled]="loading">
      <b>
        Update <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
      </b>
    </button>
  </div>
</form>
