import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL, VERSION } from '../constant/constant';
import { getTimeZoneHeader } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class AuditlogService {

  constructor(private http:HttpClient) { }

  getDataEntryLevelAuditLog(sites:any,subjects:any,visits:any,forms:any,users:any,toDate:any,fromDate:any,message:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/list?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&message=${message}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getFieldLevelAuditlog(subjectId:any,visitId:any,formId:any,fieldId:any,rowId:any,multiRowId:any,pageNo:any,pageSize:any):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/list/field/${fieldId}?subjectId=${subjectId}&visitId=${visitId}&formId=${formId}&rowId=${rowId}&multiRowId=${multiRowId}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getUserLogData(toDate:any,fromDate:any,pageNo:number,pageSize:number,message:any):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/userlog/list?toDate=${toDate}&fromDate=${fromDate}&message=${message}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getDataPointLevelAuditLog(sites:any,subjects:any,visits:any,forms:any,users:any,toDate:any,fromDate:any,message:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/field/list?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&message=${message}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getDesignLevelAuditLog(sites:any,subjects:any,visits:any,forms:any,users:any,toDate:any,fromDate:any,message:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/designlog/list?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&message=${message}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getReviewLevelAuditLog(sites:any,subjects:any,visits:any,forms:any,users:any,toDate:any,fromDate:any,message:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/reviewlog/list?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&message=${message}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  getReportAuditLog(subjectIds:any, users: any, toDate: any, fromDate: any, message: any, pageNo: number, pageSize: number,logType: number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/list?subjects=${subjectIds}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&message=${message}&pageNo=${pageNo}&pageSize=${pageSize}&logType=${logType}`);
  }

  //Export API's
  exportReportLogsToExcel(subjectIds:any, users: any, toDate: any, fromDate: any, message: any,logType: number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/reportlogExport?subjects=${subjectIds}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&message=${message}&logType=${logType}`,{headers:getTimeZoneHeader()});
  }

  getDataEntryAuditLogExport(sites:any,subjects:any,visits:any,forms:any,users:any,toDate:any,fromDate:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/export?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&pageNo=${pageNo}&pageSize=${pageSize}`,{headers:getTimeZoneHeader()});
  }

  getDesignAuditLogExport(sites:any,subjects:any,visits:any,forms:any,users:any,toDate:any,fromDate:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/designlog/export?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&pageNo=${pageNo}&pageSize=${pageSize}`,{headers:getTimeZoneHeader()});
  }

  getDataPointAuditLogExport(sites:any,subjects:any,visits:any,forms:any,users:any,toDate:any,fromDate:any,pageNo:number,pageSize:number):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/field/export?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&users=${users}&toDate=${toDate}&fromDate=${fromDate}&pageNo=${pageNo}&pageSize=${pageSize}`,{headers:getTimeZoneHeader()});
  }

  getUserLogExport(users?:any,toDate?:any,fromDate?:any):Observable<any>{
    return this.http.get(`${baseURL}auditlog/${VERSION}/userlog/export?users=${users}&toDate=${toDate}&fromDate=${fromDate}`,{headers:getTimeZoneHeader()});
  }

  postLoggedInLog():Observable<any>{
    return this.http.post(`${baseURL}auditlog/${VERSION}/login`,{headers:getTimeZoneHeader()});
  }

}
