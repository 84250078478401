import { DatePipe } from "@angular/common"
import * as moment from "moment"

export function isDateFormat(startDate:Date):Boolean {
  const res = isDateFormat(startDate)
  console.log('result MAX EQUAL : ' + res)
  return res
}

export function isDateDifference(value1:string,value2:string):number {
  let date1 = new Date(value1)
  let date2 = new Date(value2)
  
  let time = date1.getTime() - date2.getTime()
  let days = time / (1000 * 3600 * 24)
  
  return days
}
isDateDifference.transform = function (value1:string,value2:string) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = isDateDifference(value1,value2)
  console.log('result Difference In Days : ' + res)
  return res
}
export function isDateGreaterThan(value1:string,value2:string) {
  let date1 = new Date(value1)
  let date2 = new Date(value2)
  
  if (date1.getTime() > date2.getTime()){
    return true
  }else {
    return false
  }
}
isDateGreaterThan.transform = function (value1:string,value2:string) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = isDateGreaterThan(value1,value2)
  console.log('result MAX : ' + res)
  return res
}

export function isDateLessThan(value1:string,value2:string) {
  let date1 = new Date(value1)
  let date2 = new Date(value2)
  
  if (date1.getTime() < date2.getTime()){
    return true
  }else {
    return false
  }
}
isDateLessThan.transform = function (value1:string,value2:string) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = isDateLessThan(value1,value2)
  console.log('result MIN : ' + res)
  return res
}

export function isDateEqual(value1:string,value2:string) {
  let date1 = new Date(value1)
  let date2 = new Date(value2)
  
  if (date1.getTime() === date2.getTime()){
    return true
  }else {
    return false
  }
}
isDateEqual.transform = function (value1:string,value2:string) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = isDateEqual(value1,value2)
  console.log('result EQUAL : ' + res)
  return res
}

export function isDateNotEqual(value1:string,value2:string) {
  let date1 = new Date(value1)
  let date2 = new Date(value2)
  
  if (date1.getTime() === date2.getTime()){
    return false
  }else {
    return true
  }
}
isDateNotEqual.transform = function (value1:string,value2:string) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = isDateNotEqual(value1,value2)
  console.log('result NOT EQUAL : ' + res)
  return res
}
export function isDateGreaterThanOrEqual(value1:string,value2:string) {
  let date1 = new Date(value1)
  let date2 = new Date(value2)
  
  if (date1.getTime() >= date2.getTime()){
    return true
  }else {
    return false
  }
}
isDateGreaterThanOrEqual.transform = function (value1:string,value2:string) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = isDateGreaterThanOrEqual(value1,value2)
  console.log('result MAX EQUAL : ' + res)
  return res
}

export function isDateLessThanOrEqual(value1:string,value2:string) {
  let date1 = new Date(value1)
  let date2 = new Date(value2)
  
  if (date1.getTime() <= date2.getTime()){
    return true
  }else {
    return false
  }
}
isDateLessThanOrEqual.transform = function (value1:string,value2:string) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = isDateLessThanOrEqual(value1,value2)
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function addDays(value1:string,value2:number) {
  let date1 = new Date(value1)
  let addDaysCount = value2
  let new_date = moment(date1, "YYYY-MM-DD").add(addDaysCount, 'days').format('YYYY-MM-DD');
  console.log('new_date: ', new_date);
  return new_date;
}
addDays.transform = function (value1:string,value2:number) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = addDays(value1,value2)
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function addMonths(value1:string,value2:number) {
  let date1 = new Date(value1)
  let addMonthCount = value2
  let new_date = moment(date1, "YYYY-MM-DD").add(addMonthCount, 'M').format('YYYY-MM-DD');
  return new_date;
}
addMonths.transform = function (value1:string,value2:number) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = addMonths(value1,value2)
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function addYears(value1:string,value2:number) {
  let date1 = new Date(value1)
  let addYearCount = value2
  let new_date = moment(date1, "YYYY-MM-DD").add(addYearCount, 'y').format('YYYY-MM-DD');
  return new_date;
}
addYears.transform = function (value1:string,value2:number) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = addYears(value1,value2)
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function subtractDays(value1:string,value2:number) {
  let date1 = new Date(value1)
  let subtractDayCount = value2
  let new_date = moment(date1, "YYYY-MM-DD").subtract(subtractDayCount, 'days').format('YYYY-MM-DD');
  return new_date;
}
subtractDays.transform = function (value1:string,value2:number) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = subtractDays(value1,value2)
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function subtractMonths(value1:string,value2:number) {
  let date1 = new Date(value1)
  let subtractMonthCount = value2
  let new_date = moment(date1, "YYYY-MM-DD").subtract(subtractMonthCount, 'M').format('YYYY-MM-DD');
  return new_date;
}
subtractMonths.transform = function (value1:string,value2:number) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = subtractMonths(value1,value2)
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function subtractYears(value1:string,value2:number) {
  let date1 = new Date(value1)
  let subtractYearCount = value2
  let new_date = moment(date1, "YYYY-MM-DD").subtract(subtractYearCount, 'y').format('YYYY-MM-DD');
  return new_date;
}
subtractYears.transform = function (value1:string,value2:number) {
  console.log('input: a = ' + value1 + ', b = ' + value2)
  const res = subtractYears(value1,value2)
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function todaysDate() {
  let new_date = moment().format("YYYY-MM-DD");
  return new_date;
}
todaysDate.transform = function () {
  const res = todaysDate()
  console.log('result MIN EQUAL : ' + res)
  return res
}

export function isDateWindowValid(startDate:string,newDate:string):Boolean {
  console.log('input: a = ' + startDate + ', b = ' + newDate)
  const res = isDateGreaterThanOrEqual(startDate,newDate)
  console.log('result MAX EQUAL : ' + res)
  return res
}

isDateWindowValid.transform = function (startDate:string,newDate:string,visitOffset:number,_lowerLimit:number,_upperLimit:number):Boolean {
  let lowerLimit =  (_lowerLimit == null || _lowerLimit == undefined) ? 0: _lowerLimit;
  let upperLimit = (_upperLimit == null || _upperLimit ==  undefined) ? 0: _upperLimit; 
  let lowerBound = visitOffset - lowerLimit;
  let upperBound = visitOffset + upperLimit;
  let dayDifference = isDateDifference(startDate,newDate);
  if(dayDifference >= lowerBound && dayDifference <= upperBound){
    return false;
  }
  return true;
}

export function getDateByDateFormat(date: any, format: string): string {
  let datePipe:DatePipe = new DatePipe('EN')
  let dateFormatted:any = datePipe.transform(date,format)
  return 'Generated On : '+dateFormatted?.toString();
}

//BLOCK END